.CreateUser,
.UpdateUser {
    .modal-dialog {
        max-width: 700px;
        .btn {
            display: block;
        }
        .row {
            margin-bottom: 10px;
        }
    }
}
