.ProductionCentre {
    thead {
        tr {
            td, th {
                &:nth-child(1) {
                    width: 25%;
                }
                &:nth-child(2) {
                    width: 44%;
                }
                &:nth-child(3) {
                    width: 25%;
                }
                &:nth-child(4) {
                    width: 3%;
                }
                &:nth-child(5) {
                    width: 3%;
                }
            }
        }
    }
    table .data td {
        font-weight: 400;
        &:nth-child(1) {
            font-weight: 780;
        }
    }
}