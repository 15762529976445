.Story {
    .collapsibleRow {
        td {
            vertical-align: middle;
            &:nth-child(1) {
                width: 10%;
                text-align: center;
            }
            &:nth-child(2) {
                width: 12%;
                text-align: left;
            }
            &:nth-child(3) {
                width: 10%;
                text-align: center;
            }
            &:nth-child(4) {
                width: 12%;
                text-align: left;
            }
            &:nth-child(5) {
                width: 10%;
                text-align: center;
            }
            &:nth-child(6) {
                width: 12%;
                text-align: left;
            }
        }
    }
}

.Story,
.Media {
    .collapsibleRow {
        .AboutInfo {
            text-align: left;
            margin-left: 30px;
            font-size: 0.9rem;
            line-height: 1.2;
            margin-bottom: 0.5rem;
        }
    }
}

.Media {
    .collapsibleRow {
        td {
            vertical-align: middle;
            &:nth-child(1) {
                max-width: 30px;
                text-align: center;
            }
            &:nth-child(2) {
                max-width: 50%;
                text-align: left;
            }
            &:nth-child(3) {
                max-width: 30px;
                text-align: center;
            }
            &:nth-child(4) {
                max-width: 50%;
                text-align: left;
            }
        }
    }
}

.Story,
.Media {
    .collapsibleData {
        td {
            font-weight: 400;
            &.collapsedMediaRow {
                width: 34%;
                padding: 0;
            }
        }
    }
    thead {
        tr {
            th {
                &:nth-child(1) {
                    width: 5%;
                }
            }
            td {
                &:nth-child(2) {
                    width: 32%;
                }
                &:nth-child(3) {
                    width: 12%;
                }
                &:nth-child(4) {
                    width: 10%;
                }
                &:nth-child(5) {
                    width: 12%;
                }
                &:nth-child(6) {
                    width: 8%;
                }
                &:nth-child(7) {
                    width: 15%;
                }
            }
        }
        th {
            &:nth-child(8) {
                width: 2%;
                min-width: 40px;
            }
            &:nth-child(9) {
                width: 2%;
                min-width: 40px;
            }
            &:nth-child(10) {
                width: 2%;
                min-width: 40px;
            }
        }
    }
    tbody {
        tr {
            td {
                &:nth-child(3) {
                    font-family: Assistant;
                    font-weight: 400;
                }
                &:nth-child(4) {
                    font-family: Assistant;
                    font-weight: 400;
                }
                &:nth-child(5) {
                    font-family: Assistant;
                    font-weight: 400;
                }
                &:nth-child(6) {
                    font-family: Assistant;
                    font-weight: 400;
                }
                &:nth-child(7) {
                    font-family: Assistant;
                    font-weight: 400;
                }
            }
        }
    }
}

.Story,
.Media {
    thead {
        tr {
            th {
                &:nth-child(8) {
                    width: 3%;
                }
                &:nth-child(9) {
                    width: 3%;
                }
            }
        }
    }
}

//when FA Icons become too small to be clickable
@media only screen and (max-width: 800px) {
    /* Table */
    .Story,
    .Media {
        thead tr {
            td,
            th {
                &:nth-child(2) {
                    width: 25%;
                }
                &:nth-child(8) {
                    width: 6%;
                }
                &:nth-child(9) {
                    width: 6%;
                }
            }
        }
    }
}

.EditStoryData,
.EditMediaData {
    thead {
        tr {
            td, th {
                &:nth-child(1) {
                    width: 25%;
                }
                &:nth-child(2) {
                    width: 12%;
                }
                &:nth-child(3) {
                    width: 58% !important;
                }
                &:nth-child(4) {
                    width: 5%;
                }
            }
        }
    }
}