.Vocabulary {
    thead, tbody {
        tr {
            th, td {
                &:nth-child(1) {
                    width:95% !important;
                }
                &:nth-child(2) {
                    width:5% !important;
                }
                &:nth-child(3) {
                    width:5% !important;
                }
            }
            width: 100% !important;
            display: table-row !important;
        }
    }
    .parent-tbody {
        width: 100% !important;
        display: table-row-group !important;
    }
}