$yellow-theme: #ffbb33;
$orange-theme: #f69b1d;
$dark-theme: #212121;

/* User Dropdown: */
.userDropdown {
    background-color: rgba(28, 30, 32, 0.8);
    width: 100%;
    margin-left: 10px;
    a {
        color: white !important;
        &:hover {
            background-color: #ffbb33 !important;
        }
    }
}

.TopNav .userDropdownToggle {
    z-index: 900;
    cursor: pointer;
    .fa-caret-down {
        font-size: 15px;
        margin-left: 5px;
        transform: translateY(-50%);
    }
    svg,
    .userText {
        display: inline-block;
        .authorityDisplay {
            font-size: 13px;
        }
        line-height: 20px;
    }
    .fa.fa-user {
        font-size: 27px;
        transform: translateY(-10%);
    }
}

.TopNav {
    position: fixed;
    width: 100%;
    top: 0;

    //issue: the clock moves the dropdown div a lot...
    .oneDigit {
        .fa-container {
            position: absolute;
            right: 155px;
            width: auto;
            white-space: nowrap;
        }
    }
    .twoDigits {
        .fa-container {
            position: absolute;
            right: 170px;
            width: auto;
            white-space: nowrap;
        }
    }

    .activeMainContent {
        color: #f69b1d;
        padding-left: 1%;
        font-family: Roboto;
        font-weight: 900;
        font-size: 32px;
        width: 100%;
    }
    .fa-container {
        position: relative;
        display: inline-block;
        font-size: 18px;
        color: white;
        .fa {
            margin-right: 10px;
            color: white;
            align-items: right;
            margin-left: 5px;
            font-size: 25px;
        }
        .fa-user {
            font-size: 20px;
        }
        .fa-caret-down {
            font-size: 15px;
        }
        &:hover,
        &:hover .fa {
            color: #fec122;
        }
    }

    .navbar-brand {
        position: fixed;
        left: 55%;
    }

    .navbar {
        background-color: rgba(52, 58, 64, 0.9) !important;
        .topRight {
            position: absolute;
            top: 15px;
            right: 250px;
            height: 80%;
            display: inline-block;
        }
        .ClockText {
            font-weight: 800;
            font-family: RobotoMono;
            letter-spacing: -1.5px;
            color: $orange-theme;
            font-size: 23px;
            position: relative;
            display: inline-block;
            margin-top: 5px;
            .TT {
                margin-top: 5px;
                font-family: Roboto;
                font-weight: 600;
                top: 1px;
                position: relative;
            }
            svg {
                margin-right: 5px;
            }
            time {
                display: inline-block;
            }
            span {
                vertical-align: text-bottom;
                margin-left: 5px;
            }
            .react-clock__face {
                background-color: white;
            }
            .react-clock__hand__body,
            .react-clock__mark__body {
                background-color: black;
            }
            .react-clock__hour-hand__body {
                width: 2px !important;
            }
            .react-clock__minute-hand__body {
                width: 1.5px !important;
            }
            .react-clock__second-hand__body {
                background-color: $orange-theme;
            }
            .react-clock__mark {
                display: none;
            }
        }
        .NewContentBadge {
            top: 20px;
            cursor: pointer;
            &.disabled {
                cursor: auto;
            }
            color: white;
            position: absolute;
            left: 40%;
            svg {
                position: relative;

                font-size: 30px;
            }
            .NewContentNum {
                position: absolute;
                left: 20px;
                top: 20px;

                background: $yellow-theme;
                //background: radial-gradient(circle at 50% 50%, #d7f681, #efc976 10%, #ff8800 80%, #452506 100%);
                text-align: center;
                border-radius: 5rem;
                padding-left: 8px;
                padding-right: 8px;
                color: $dark-theme;
                font-weight: 700;
                /*
                &:before {
                    content: "";
                    position: absolute;
                    top: 2%;
                    left: 5%;
                    width: 80%;
                    height: 80%;
                    border-radius: 50%;
                    background: radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 50%);
                    filter: blur(1px);
                    z-index: 2;
                }*/

                //animation:
                &.Bounce {
                    animation: bounce 1s alternate;
                }
            }
            &:hover {
                svg {
                    color: $yellow-theme;
                }
                .NewContentNum {
                    background: $orange-theme;
                }
            }
        }
    }

    .fa:hover {
        color: #fec122 !important;
    }
}

@keyframes Bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-15px);
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1124px) {
    /* Table */
    .TopNav .navbar .topRight {
        position: absolute;
        top: 15px;
        right: 235px;
        height: 80%;
        display: inline-block;
    }
}

@media only screen and (min-width: 501px) and (max-width: 1024px) {
    /* Table */
    .activeMainContent {
        margin-left: 30px;
    }
}

@media only screen and (max-width: 1024px) {
    /* Table */
    .TopNav .navbar {
        .topRight {
            position: absolute;
            top: 15px;
            right: 40px;
            height: 80%;
            display: inline-block;
        }

        .NewContentBadge {
            left: 50%;
        }
    }
}

//Top Navbar Icon Mode
@media only screen and (max-width: 1000px) {
    /* Table */
    .TopNav {
        .oneDigit,
        .twoDigits {
            .fa-container {
                right: 30px;
            }
        }
        .activeMainContent {
            svg {
                font-size: 30px;
                margin-left: 10px;
            }
        }
        .navbar .NewContentBadge {
            left: 110px;
        }
    }
}
