.CreateNewStory,
.EditStoryData,
.EditMediaData,
.UploadMediaFile {
    .modal-dialog {
        max-width: 700px;
        .modal-content {
            .modal-body {
                form {
                    &.StoryDetailsForm,
                    &.ElementDetailsForm {
                        width: 100%;
                        max-width: inherit;
                    }
                }
                .uploadMediaButton {
                    z-index: 1;
                    height: 37px;
                    float: right !important;
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: 0;
                    pointer-events: none;
                    padding-left: 30px;
                    padding-right: 30px;
                }
                .custom-file-label.uploading {
                    background-color: #d0d0d0;
                }
                .custom-file {
                    margin-top: 10px;
                }
                .formTitle {
                    font-size: 18px;
                }
                .LargeCheckbox {
                    margin-top: 5px;
                }
                textarea#storyText {
                    margin-top: 25px;
                }
            }
        }
    }
}
