/*About:*/

$yellow-theme: #ffbb33;
$orange-theme: #f69b1d;
$dark-theme: #212121;

.Success {
    color: rgb(46, 230, 46);
}

.UpdateError {
    color: red;
}

.nav-tabs {
    margin-left: 1rem !important;
    .nav-link {
        color: $dark-theme !important;
        &.active {
            background-color: $dark-theme !important;
            border-color: rgba(224, 224, 224, 0) !important;
            color: $yellow-theme !important;
            border-radius: 1rem !important;
        }
        &:hover {
            color: $orange-theme !important;
            border-color: rgba(224, 224, 224, 0) !important;
        }
    }
    border-color: rgba(224, 224, 224, 0) !important;
}

/*for new hub page: */

.About {
    margin-right: 2.5%;
    padding-top: 20px;
}
.About,
.Hub {
    padding-bottom: 10%;
    .HubTabRow {
        margin-top: 20px;
        .tab-content {
            padding-top: 1rem;
            padding-left: 0;
            padding-right: 1.5rem !important;

            /*create a background container for it: */
            background: #e6e6e6;
            padding: 15px;
            border-radius: 2rem;
            margin-right: 1.5rem;
            margin-left: 1rem;
            margin-top: 0.5rem;
        }
    }
    .LogoOuterContainer {
        max-width: 300px;
        cursor: pointer;

        &:hover {
            .AboutLogo {
                filter: sepia(100%) saturate(350%) brightness(50%) hue-rotate(0deg);
                opacity: 0.3;
            }
            .LogoContainer .cam {
                opacity: 0.4;
            }
        }

        .LogoContainer {
            display: unset;
            position: initial;
            .AboutLogo {
                transition: filter 0.5s, opacity 0.5s;
            }
            .cam {
                position: absolute;
                top: 30%;
                left: 40%;
                color: white;
                background: black;
                padding: 10px;
                font-size: 45px;
                border-radius: 2rem;
                opacity: 0;
                transition: opacity 0.5s;
            }
        }
    }
    .LogoDescription {
        h5,
        p {
            margin-left: 15px;
        }
        h5 {
            margin-bottom: 15px;
        }
        p {
            line-height: 10px;
            i {
                line-height: 20px;
            }
        }
    }
    .HubInfoCard {
        margin-top: 20px;
        .card-header {
            padding-bottom: 0;
            p {
                margin-top: -8px;
                margin-bottom: 5px;
            }
        }
        .card-body {
            padding: 0.75rem 0.25rem 0.75rem 0.3rem;
            .HubCol {
                max-width: 400px;
            }
        }
    }
    .HubNameRow {
        h5,
        .form-control {
            margin-left: 0.5rem;
        }
        .form-control {
            position: relative;
            display: inline-block;
            width: 70%;
        }
        .updateButton {
            margin: 0;
            margin-left: 10px;
            padding: 6px 10px 6px 10px;
        }
        .updateLicensingButton {
            float: right;
            word-spacing: 99999px;
            width: 128px;
            line-height: 14px;
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
    .Success {
        margin-top: 10px;
        margin-left: 10px;
        margin-bottom: 0;
    }
    .TableContent {
        margin-top: 0 !important;
        .editProductionGroupRow {
            cursor: pointer;
            &:hover svg {
                color: white;
            }
        }
    }
    .thickTop {
        padding-top: 0;
    }
    .pageNavRow {
        padding-bottom: 0;
    }
}

.About,
.Hub {
    position: relative;
    overflow-y: hidden;
    animation: AboutEntry 1s;
    animation-fill-mode: forwards;
    width: 100%;
    min-height: 95%;

    .updateButton,
    .resetButton {
        background-color: #2e2e2e !important;
        margin-left: 25px;
        margin-top: 20px;
        border-radius: 0.5rem;
        //animation: AboutButtonEntry 1.5s forwards;
        &:hover {
            background-color: #ffbb33 !important;
        }
    }

    .resetButton {
        padding: 6px 30px 6px 30px;
        margin-left: auto;
    }

    .AboutInfo {
        .settingsInputSpan {
            display: block;
            overflow: hidden;
        }
        .settingsInput {
            padding: 0;
            height: 20px !important;
            text-align: center;
            width: 40px;
            display: inline-block;
            position: relative;

            &:focus {
                border-color: rgba(255, 140, 0, 0.85);
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(255, 187, 51, 0.5);
            }
        }
    }

    .HubInfoCard,
    .StatisticCard {
        .tab-content {
            padding-bottom: 0;
        }
        .nav-tabs {
            border-bottom: 1px solid #e0e0e0;
            width: 113%;
            .nav-link.active {
                background-color: #ffbb33;
                &:hover {
                    color: #212121;
                }
            }
            a {
                color: #212121;
                &:hover {
                    color: #ffbb33;
                }
            }
        }
    }

    .HubInfoCard {
        .Success {
            margin-left: 25px;
        }

        .UpdateError {
            margin-left: 25px;
        }
        .col-8 {
            z-index: 100;
        }
        .AboutLogoContainer {
            z-index: 100;
        }
        /* padding-top:30px; */
        //animation: AboutCardEntry 1s;
        //animation-delay: 0.5s;
        //animation-fill-mode: forwards;
        z-index: 100;
        margin-top: 20px;
        .tab-content {
            margin-left: 1rem;
            margin-right: 1rem;

            #uncontrolled-tab-example-tabpane-clean-up {
                width: 140%;
            }
        }

        .subSettingContainer {
            overflow: auto;
            margin-bottom: 20px;
            margin-left: 20px;
        }
        .AboutInfo {
            margin-left: 0;
        }
    }

    .StatisticCard {
        /* padding-top:30px; */
        //animation: AboutCardEntry 1s;
        animation-delay: 0.8s;
        animation-fill-mode: forwards;
        margin-top: 20px;
        margin-right: 30px;
        opacity: 0;

        .Success,
        .UpdateError {
            margin-top: 10px;
            margin-bottom: 0px;
            width: 150%;
        }

        .tab-pane {
            margin-left: -10px;
        }

        ul {
            list-style-type: none;
        }
        .subTitle {
            font-weight: 500;
        }
        .chartIcon {
            position: relative;
            border-radius: 50%;
            width: 90%;
            height: auto;
            padding-top: 75%;
            margin-top: 25px;
            background: #ffbb33;
        }
        .globeCol {
            display: table;
        }
        .btn {
            background-color: #212121 !important;
            &:hover {
                background-color: #ffbb33 !important;
            }
            margin-top: 15px;
            margin-left: -3px;
        }
        .deleteBtn {
            position: absolute;
            margin-left: 5px;
        }
        .addLocButton {
            margin-left: 5px;
            cursor: pointer;
            font-size: 20px;
            margin-top: 2%;
            margin-bottom: 1%;
            &:hover {
                color: #ffbb33;
            }
            &.remove {
                position: relative;
                display: inline;
                &.disabled {
                    pointer-events: none;
                    opacity: 0.3;
                }
            }
        }
    }
    .card {
        border-radius: 0.5rem;
    }

    .AboutLogoContainer,
    .AboutStatsLogo {
        margin-top: 20px;
        width: 90%;
        max-width: 200px;
        position: relative;
    }

    .geoLatInput,
    .geoLonInput {
        font-size: 10px !important;
        height: 100% !important;
        width: 43%;
        margin-bottom: 3px;
        float: left;
        position: relative;
        display: inline-block;
    }

    .geoLatInput {
        margin-right: 2%;
    }

    .geoInputs {
        width: 140%;
    }

    .AboutLogo {
        width: 100%;
    }

    .errorStatus {
        color: yellow;
        font-weight: 900;
        text-align: center;
        margin-top: 10px;
    }

    .custom-file-label,
    .custom-file-label::after {
        height: 33px !important;
        font-size: 14px;
    }

    .faContainer {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 20px;
        max-height: 200px;
        cursor: pointer;

        background: rgba(0, 0, 0, 0.5);
        border-radius: 1.5rem;
        padding: 5px;
        svg {
            color: rgb(224, 223, 223);
        }
        &:hover svg {
            color: #ffbb33;
        }
    }

    .AboutSettings {
        position: relative;
        z-index: 150;
        width: 100%;
        .card-body {
            padding-left: 0 !important;
        }
        .AboutSettingsInner {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 10px;
            .form-control {
                margin-right: 15px;
                background: rgba(0, 0, 0, 0.35);
                color: white !important;
                &:focus {
                    border-color: #ffbb33;
                    box-shadow: 0 0 0 0.2rem rgba(255, 187, 51, 0.45);
                }
                &::placeholder {
                    color: rgb(204, 204, 204) !important;
                }
            }
        }
        .btn-dark {
            display: block;
            margin: auto;
            margin-top: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
            &:hover {
                background-color: #ffbb33 !important;
            }
        }
        .card {
            background-color: rgba(0, 0, 0, 0.55);
            color: white;
        }
    }
    .card-title {
        padding-bottom: 15px;
        font-size: 27px;
    }
    .AboutInfo {
        margin-left: 0;
        margin-bottom: 0.2rem;
        padding-left: 0;
        padding-right: 1% !important;
    }
}

.About {
    min-height: 110%;
    margin-bottom: 40px;
    .col-12 {
        padding-right: 15px !important;
        .card-body {
            padding-right: 1.25rem !important;
        }
    }
    .AboutLogo {
        max-width: 500px;
        margin: auto;
        position: relative;
        display: block;
    }
}

@keyframes AboutEntry {
    0% {
        background-color: white;
    }
    100% {
        background-color: #d0d0d0;
    }
}

@keyframes AboutButtonEntry {
    0% {
        background-color: rgba(33, 33, 33, 0);
        opacity: 0;
    }
    30% {
        background-color: rgba(33, 33, 33, 0);
        opacity: 0;
    }
    100% {
        background-color: #212121;
        opacity: 1;
    }
}

@keyframes AboutCardEntry {
    0% {
        margin-top: 100px;
        opacity: 0;
    }
    100% {
        margin-top: 15px;
        opacity: 1;
    }
}

@media only screen and (min-width: 501px) and (max-width: 1024px) {
    .About {
        width: 100%;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 2050px) {
    .purgeDesc {
        width: 72%;
    }
}
