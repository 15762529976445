$yellow-theme: #ffbb33;
$orange-theme: #f69b1d;
$dark-theme: #212121;

.MainContent {
    padding-left: 18%;
    width: 100%;
    padding-top: 46px;
}

/* Responsive media queries: */
@media only screen and (max-width: 1024px) {
    .MainContent {
        padding-left: 0;
    }
}

/* For Static width navbar later:*/
@media only screen and (min-width: 1295px) {
    .MainContent {
        padding-left: 230px !important;
    }
}

/* Radio button customization */
.form-check-input[type="radio"]:not(:checked) + label:before,
.form-check-input[type="radio"]:checked + label:before {
    width: 24px;
    height: 24px;
    border: 2px solid #5a5a5a;
}
.form-check-input[type="radio"] + label:after {
    margin-left: 8px;
    margin-top: 8px;
}

.form-check-input[type="radio"]:checked + label:after {
    background-color: $orange-theme;
    border: 2px solid $orange-theme;
}

.form-control:focus {
    border-color: $orange-theme !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 196, 2, 0.87) !important;
}

table.table-hover tbody tr:hover {
    background-color: rgba(255, 196, 0, 0.3);
}

.md-form textarea.md-textarea:focus:not([readonly]) {
    box-shadow: 0 1px 0 0 $yellow-theme;
    border-bottom: 1px solid $yellow-theme;
}
.md-form input[type="text"]:focus:not([readonly]) + label,
.md-form input[type="password"]:focus:not([readonly]) + label,
.md-form input[type="email"]:focus:not([readonly]) + label,
.md-form input[type="url"]:focus:not([readonly]) + label,
.md-form input[type="time"]:focus:not([readonly]) + label,
.md-form input[type="date"]:focus:not([readonly]) + label,
.md-form input[type="datetime-local"]:focus:not([readonly]) + label,
.md-form input[type="tel"]:focus:not([readonly]) + label,
.md-form input[type="number"]:focus:not([readonly]) + label,
.md-form input[type="search-md"]:focus:not([readonly]) + label,
.md-form input[type="search"]:focus:not([readonly]) + label,
.md-form textarea.md-textarea:focus:not([readonly]) + label {
    color: $orange-theme;
}
.md-form textarea ~ label.active {
    color: #757575;
}
.disabledWithTooltip.disabled {
    pointer-events: auto !important;
    opacity: 0.4;
    cursor: default !important;
    &:hover svg {
        //color: #474747 !important;
        opacity: 0.4;
    }
    &:hover {
        //background-color: #474747 !important;
        opacity: 0.4;
    }
}

div.disabled {
    cursor: none;
    svg {
        cursor: none;
        opacity: 0.4;
    }
}

svg.disabled {
    opacity: 0.5;
    cursor: auto;
    &:hover {
        color: #212121;
    }
}

.modal-content .modal-body .btn.disabledWithTooltip.disabled {
    &:hover {
        background-color: #474747 !important;
        opacity: 0.4;
    }
}

.disabledWithTooltip {
    cursor: auto !important;
}

.form-control:disabled::placeholder {
    font-style: italic;
}

//checkbox global {
.LargeCheckbox .form-check-input[type="checkbox"] + label:before {
    width: 30px;
    height: 30px;
}
.LargeCheckbox .form-check-input[type="checkbox"]:checked + label:before {
    width: 15px;
    height: 2rem;
}

//cross:
.cross {
    .form-check-input[type="checkbox"] + label:before {
        transform: translate(-50%, 0);
    }
    .form-check-input[type="checkbox"]:checked + label:before {
        top: -6px;
        content: "X";
        color: $yellow-theme;
        font-size: 22px;
        transform: rotate(0) translate(50%, 0);
        border-right: 2px solid rgba(255, 187, 51, 0);
        border-bottom: 2px solid rgba(255, 187, 51, 0);
    }
}

.MainContent .TableToolbar .checkFilter:hover .cross .form-check-input[type="checkbox"]:checked + label::before {
    border-right: 2px solid rgba(255, 187, 51, 0);
    border-bottom: 2px solid rgba(255, 187, 51, 0);
    color: white;
}

.rowActionTooltip {
    pointer-events: none; //as tooltip is outside of the modal, clicking on it causes the modal to quit
    white-space: pre-wrap;
}
.addRowEditor .arrow {
    margin-left: 30px;
}

.disableClick {
    pointer-events: none;
}

/*===================BURLI RADIO BUTTON STYLE=============================*/
/* Customize the label (the container) */
.radioContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radioContainer {
    font-size: 15px;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 50%;
        /* Create the indicator (the dot/circle - hidden when not checked) */
        &:after {
            content: "";
            position: absolute;
            display: none;

            /* Style the indicator (dot/circle) */
            top: 9px;
            left: 9px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
        }
    }
    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
    input:checked ~ .checkmark {
        background-color: $orange-theme;
        /* Show the indicator (dot/circle) when checked */
        &:after {
            display: block;
        }
    }
}
/*==================================================================================*/

/* The style below works with material UI - TextField: */
.MuiFormControl-root {
    width: 100%;
}
.MuiTextField-root.MuiFormControl-root {
    input {
        color: black !important;
    }
    .Mui-focused, &:focus, div:focus {
        color: $yellow-theme;
    }
    .MuiInput-underline:after {
        border-color: $yellow-theme;
    }
    .MuiFormHelperText-root.Mui-error, .MuiFormLabel-root.Mui-error {
        color: red;
    }
    .MuiFormLabel-root {
        top: -3px;
        font-size: 1.2rem;
        &.MuiInputLabel-shrink {
            top: 1px;
        }
    }
}