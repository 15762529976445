$yellow-theme: #ffbb33;
$orange-theme: #f69b1d;
$dark-theme: #212121;
$semidark-theme: #474747;

#manageVocabPopover {
    border-radius: 1.2rem;
    max-width: 800px;
    height: 600px;
    top: 200px !important;
    z-index: 0;
    #manageVocabPopoverTitle {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        background-color: $dark-theme;

        color: #f69b1d;
        font-family: Roboto;
        font-weight: 900;
        font-size: 28px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    #manageVocabPopoverContent {
        width: 680px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 510px;
        //scroll bar:
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        &::-webkit-scrollbar {
            width: 10px;
        }
        .TableToolbarTop .col-4 {
            margin-top: 8px;
        }
    }
    .closeButton {
        position: absolute;
        right: 0px;
        top: -5px;
        padding: 1rem 1rem;
        cursor: pointer;
        svg {
            font-weight: bolder;
            font-size: 20px;
            color: white;
        }
        &:hover svg {
            color: $orange-theme;
        }
    }
    .structureRow {
        padding-top: 0;
    }
    .TableContent {
        margin-top: 0 !important;
        .filterSearchDiv {
            margin-top: 8px;
            svg {
                top: 20px;
            }
        }
        .rowEditor {
            .arrow {
                &::before,
                &::after {
                    top: 1px !important;
                }
            }
        }
    }
    .arrow {
        &::before,
        &::after {
            top: 85px;
        }
    }
    .onDelete .arrow {
        &::before,
        &::after {
            top: 1px;
        }
    }
}

//Phone
@media only screen and (max-width: 1000px) {
    #manageVocabPopover {
        max-width: 80%;
        #manageVocabPopoverContent {
            width: 100%;
        }
    }
}

//Phone
@media only screen and (max-height: 800px) {
    #manageVocabPopover {
        max-height: 100%;
        transform: none;
        top: 0 !important;
        overflow-y: auto;
    }
}