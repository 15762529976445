
$yellow-theme: #ffbb33;

.ScreenSizeWarning {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    background: rgb(75,75,75);
    background: linear-gradient(180deg, rgba(75,75,75,1) 0%, rgba(54,51,40,1) 35%, rgba(0,37,45,1) 100%);
    overflow: hidden;

    .container {
        position: fixed;
        top: 15%;
        left: 15%;
        width: 65%;

        img {
            width: 100%;
            filter: brightness(50%) sepia(100%) saturate(350%);
        }
        h1, p {
            color: $yellow-theme; 
            text-align: center;
         }

         h1 {
            font-weight: 500;
            font-size: 30px;
         }

    }
    
}