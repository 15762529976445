.Footer {
    position: fixed;
    bottom: 0px;
    height: 50px;
    z-index: 9999;
    width: 82%;
    .fa {
        font-size: 15px;
        margin-right: 20px;
        font-weight: 400;
    }
    .navbar-brand {
        position: fixed;
        left: 55%;
    }
    p {
        text-align: center;
        width: 82%;
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 15px;
    }
}

@media only screen and (max-width: 600px) {
    .Footer .fa {
        margin-right: 0px !important;
    }
}

@media only screen and (min-width: 501px) and (max-width: 1024px) {
    .Footer p {
        width: 100%;
    }
}

@media only screen and (min-width: 501px) {
    .Footer {
        width: 100%;
        .navbar {
            height: 40px;
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            .fa {
                font-size: 20px;
            }
        }
    }
}

/*Smaller footer*/
@media only screen and (max-width: 1000px) {
    .Footer {
        width: 100%;
        .navbar {
            p {
                font-size: 9px;
            }
        }
    }
}

