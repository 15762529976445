$yellow-theme: #ffbb33;
$orange-theme: #f69b1d;
$dark-theme: #212121;
$semidark-theme: #474747;

//need to declare form here, or else it conficts with tooltips
.ExpandDiv, .UnexpandDiv {
    #arrowAnim {
        width: 30px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.leftArrow .arrow {
            transform: rotate(-45deg);
        }
        &.rightArrow .arrow {
            transform: rotate(135deg);
        }
    }

    .arrow {
        width: 10px;
        height: 10px;
        border: 5px solid;
        border-color: #f69b1d transparent transparent #f69b1d;
    }

    .leftArrowSliding {
        position: absolute;
        -webkit-animation: slideLeft 1s linear infinite;
        animation: slideLeft 1s linear infinite;
    }

    .rightArrowSliding {
        position: absolute;
        -webkit-animation: slideRight 1s linear infinite;
        animation: slideRight 1s linear infinite;
    }


    .delay1 {
        -webkit-animation-delay: 0.15s;
        animation-delay: 0.15s;
    }
    .delay2 {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    .delay3 {
        -webkit-animation-delay: 0.45s;
        animation-delay: 0.45s;
    }
    .delay4 {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
    .delay5 {
        -webkit-animation-delay: 0.75s;
        animation-delay: 0.75s;
    }
    .delay6 {
        -webkit-animation-delay: 0.88s;
        animation-delay: 0.88s;
    }
    .delay7 {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
    }
    @keyframes slideLeft {
        0% {
            opacity: 0;
            transform: translateX(3vw);
        }
        20% {
            opacity: 1;
            transform: translateX(2vw);
        }
        80% {
            opacity: 1;
            transform: translateX(-2vw);
        }
        100% {
            opacity: 0;
            transform: translateX(-3vw);
        }
    }
    @keyframes slideRight {
        0% {
            opacity: 0;
            transform: translateX(-3vw);
        }
        20% {
            opacity: 1;
            transform: translateX(-2vw);
        }
        80% {
            opacity: 1;
            transform: translateX(2vw);
        }
        100% {
            opacity: 0;
            transform: translateX(3vw);
        }
    }
}
