$yellow-theme: #ffbb33;
$orange-theme: #f69b1d;
$dark-theme: #212121;

.MainContent {
    .CreateDataField,
    [class*="EditDataField"] {
        .HubUserForm .Vocabulary .TableContent {
            thead {
                tr {
                    td {
                        &:nth-child(1) {
                            width: 90%;
                        }
                        &:nth-child(2) {
                            width: 5%;
                        }
                        &:nth-child(3) {
                            width: 5%;
                        }
                    }
                }
            }
            tbody {
                display: table;
                width: 105.3%;
                tr {
                    td {
                        &:nth-child(1) {
                            width: 90%;
                        }
                        &:nth-child(2) {
                            width: 5%;
                        }
                        &:nth-child(3) {
                            width: 5%;
                        }
                    }
                }
            }
            ::-webkit-scrollbar {
                width: 7px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: #888888;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: #555555;
            }

            .parent-tbody {
                max-height: 100px;
            }
            .addRow-tbody {
                tr {
                    width: 100%;
                    display: inline-flex;
                    padding-right: 0;
                    td {
                        &:nth-child(1) {
                            display: inline-block;
                        }
                        &:nth-child(2).InputAddRow {
                            width: 10%;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
.vocabTooltip {
    padding-left: 40px !important;
}

.MainContent {
    .CreateDataField,
    [class*="EditDataField"] {
        .HubUserForm .Vocabulary {
            .TableContent {
                thead {
                    tr {
                        td,
                        th {
                            &:nth-child(1) {
                                width: 95%;
                            }
                        }
                    }
                }
                .parent-tbody {
                    width: 100%;
                }
                td,
                th {
                    &:nth-child(1) {
                        width: 95%;
                    }
                    &:nth-child(2) {
                        width: 5%;
                        padding-left: 0;
                        cursor: pointer;
                        &:hover svg {
                            color: $orange-theme;
                        }
                        &.disabled svg {
                            opacity: 0.4;
                        }
                    }
                    .form-control {
                        font-size: 12px !important;
                        height: 21px !important;
                        padding: 0.25rem;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 92%;
                    }
                    &:nth-child(2).onDelete {
                        width: 7.1%;
                        svg {
                            margin-left: 55%;
                        }
                    }
                }
            }
            table .data td {
                font-weight: 400;
                &:nth-child(1) {
                    font-weight: 780;
                }
            }
        }
    }
}

[class*="EditDataField"],
.CreateDataField {

    .btn {
        text-transform: none;
    }
    .md-form {
        margin-right: 25px;
    }
    .form-check {
        margin-left: 15px;
        margin-top: 5px;
    }
    .HubUserForm .generatedTable .parent-tbody {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100px;
    }
    .HubUserForm {
        max-width: 780px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 10px;
        .descriptionCol .md-form {
            margin-top: 0;
            height: 20%;
        }
        .descriptionCol {
            padding-left: 0;
            padding-top: 10px;
            margin-bottom: -20px;
        }
        .SelectionStatus {
            div {
                padding-left: 5px;
                &.statusText {
                    margin-top: 5px;
                }
                .metadataFormButtonsContainer {
                    margin: auto;
                    position: relative;
                    display: table;
                    width: auto;
                    padding: 0;
                    display: flex;
                    .btn {
                        font-size: 15px;
                        padding: 7px 14px 7px 14px;
                        border-radius: 1rem;
                        margin-top: 40px;
                        display: inline-block;
                    }
                }
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        .status div {
            margin: auto;
            .Success,
            .UpdateError {
                margin: 0;
            }
        }
        .HubNameRow,
        .TitleRow div {
            padding-left: 0;
        }
        .TitleRow h5 {
            padding-top: 20px;
            font-size: 14px;
            color: #757575;
            &.focused {
                color: $orange-theme;
            }
        }
        .structureRow {
            padding-top: 0;
            margin-top: -5px;
            table.table td {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }
        }
        //allowing table scroll:
        .parent-tbody {
            display: block;
            overflow-y: auto;
            max-height: 200px;
            width: 1006%;
            tr {
                display: block;
            }
            td {
                &:nth-child(1) {
                    width: 1%;
                }
                &:nth-child(2) {
                    padding-left: 3%;
                    width: 37%;
                }
                &:nth-child(3) {
                    padding-left: 2%;
                }
            }
        }

        .filterSearchDiv {
            margin-top: 10px;
            svg {
                margin-top: 5px;
            }
        }
    }

    .modal-content .modal-header .modal-title {
        font-size: 28px;
    }
    .md-form input[type="text"]:focus:not([readonly]) {
        box-shadow: 0 1px 0 0 $yellow-theme;
        border-bottom: 1px solid $yellow-theme;
        & + label {
            color: $orange-theme;
        }
    }
    label {
        margin-left: -5px;
    }

    .modal-dialog {
        max-width: 600px;
        .modal-content .modal-body {
            padding: 0 !important;
            overflow-x: inherit;
        }
    }
    input.form-control,
    .form-group {
        margin-bottom: 0;
    }
}

.editRow button {
    display: inline-block;
    float: right;
    margin-top: 11px !important;
}