/*SideNav Footer: */
.NavBarFooter {
  position: absolute;
  bottom: 13%;
  width: 95%;
  left: 5%; }
  .NavBarFooter .col-6 {
    padding-left: 0;
    margin-right: 5px;
    margin-top: 15px; }
  .NavBarFooter p,
  .NavBarFooter h5 {
    color: #f69b1d;
    font-size: 0.82rem;
    line-height: 16px;
    position: absolute;
    bottom: 25%;
    font-weight: 600; }
    .NavBarFooter p .hubSpecs,
    .NavBarFooter h5 .hubSpecs {
      color: white;
      line-height: 14px;
      font-size: 0.7rem;
      font-weight: 300; }
  .NavBarFooter .col-5,
  .NavBarFooter .col-7 {
    padding: 2px !important; }
  .NavBarFooter .AboutButton {
    color: white; }
    .NavBarFooter .AboutButton svg {
      margin-right: 3px; }
    .NavBarFooter .AboutButton:hover {
      color: #f69b1d; }

.imgCard {
  display: block;
  margin: auto;
  width: 90%;
  margin-bottom: 40px;
  margin-top: 40px; }

.SideNavbar {
  background-color: #1c1e20 !important;
  height: 120vh;
  position: fixed;
  width: 18%;
  left: 0;
  top: -30px;
  z-index: 300;
  max-width: 235px; }
  .SideNavbar div, .SideNavbar a {
    font-family: Roboto; }
  .SideNavbar .sideNavMain {
    height: 100%;
    padding: 0 !important;
    background-color: rgba(255, 0, 0, 0);
    padding: 0; }
    .SideNavbar .sideNavMain .flex-column {
      position: absolute;
      top: 30px;
      width: 100%; }
      .SideNavbar .sideNavMain .flex-column .nav-link {
        padding-top: 8px;
        padding-bottom: 8px; }
      .SideNavbar .sideNavMain .flex-column .sidebarWrapper {
        /* To prevent animation glitching */
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        /*Subnav multiplelines:*/ }
        .SideNavbar .sideNavMain .flex-column .sidebarWrapper a {
          display: block;
          margin-left: 0;
          padding-left: 25px;
          padding-top: 0 !important;
          padding-bottom: 0 !important; }
        .SideNavbar .sideNavMain .flex-column .sidebarWrapper:hover,
        .SideNavbar .sideNavMain .flex-column .sidebarWrapper:hover .subNav svg {
          background-color: #fec122 !important; }
          .SideNavbar .sideNavMain .flex-column .sidebarWrapper:hover a,
          .SideNavbar .sideNavMain .flex-column .sidebarWrapper:hover .subNav svg a {
            color: black !important; }
        .SideNavbar .sideNavMain .flex-column .sidebarWrapper .multilineNav {
          width: 50px;
          display: inline-block; }
          .SideNavbar .sideNavMain .flex-column .sidebarWrapper .multilineNav svg {
            margin-bottom: 29%; }
        .SideNavbar .sideNavMain .flex-column .sidebarWrapper .multilineNavText {
          width: 65%;
          display: inline-block; }
        .SideNavbar .sideNavMain .flex-column .sidebarWrapper .subNav {
          margin-left: 12px;
          padding: 10px; }
          .SideNavbar .sideNavMain .flex-column .sidebarWrapper .subNav:hover a {
            color: #ffffff; }
      .SideNavbar .sideNavMain .flex-column .sidebarWrapperActive,
      .SideNavbar .sideNavMain .flex-column .sidebarWrapperActive .subNav svg {
        background-color: #f69b1d; }
      .SideNavbar .sideNavMain .flex-column .sidebarWrapperActive a {
        color: black !important; }
      .SideNavbar .sideNavMain .flex-column .sidebarWrapperParentActive {
        background-color: #f69b1d;
        color: black !important; }
      .SideNavbar .sideNavMain .flex-column .nav-link:hover {
        background-color: #fec122 !important; }
      .SideNavbar .sideNavMain .flex-column a {
        color: #fec122 !important;
        /*prevent them from aligning to middle altogether*/
        text-align: left; }
        .SideNavbar .sideNavMain .flex-column a:hover {
          color: #1c1e20 !important;
          text-decoration: none !important; }
    .SideNavbar .sideNavMain a svg {
      margin-left: 20px; }

.sideNavActive {
  background-color: #ff8c00; }
  .sideNavActive a {
    color: #1c1e20 !important; }

.sideNavInactive {
  background-color: rgba(255, 255, 255, 0); }

.mobileNavActivator {
  visibility: collapse; }

/* Responsive media queries: */
@media only screen and (max-width: 501px) {
  .SideNavbar .sideNavMain {
    width: 320%; }
    .SideNavbar .sideNavMain .flex-column .nav-link {
      padding-top: 10;
      padding-bottom: 10; }
    .SideNavbar .sideNavMain .flex-column .sidebarWrapper {
      padding-top: 5px;
      padding-bottom: 5px; }
    .SideNavbar .sideNavMain a {
      font-size: 18px !important; }
    .SideNavbar .sideNavMain .imgCard {
      margin-top: 20px;
      margin-bottom: 5px; }
  .NavBarFooter {
    width: 300%; }
  .imgCard {
    height: 80px; }
  .slideHorizontal {
    left: -220px !important;
    transform: translateX(0px) !important;
    transition: transform 200ms ease-in !important; }
    .slideHorizontal.slideIn {
      transform: translateX(220px) !important; }
    .slideHorizontal.slideOut {
      transform: translateX(0px) !important; } }

/* Responsive media queries: */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .SideNavbar {
    width: 30% !important;
    left: -310px !important;
    transform: translateX(0px) !important;
    transition: transform 200ms ease-in !important; }
    .SideNavbar.slideIn {
      transform: translateX(310px) !important; }
    .SideNavbar.slideOut {
      transform: translateX(0px) !important; } }

/* Responsive media queries: */
@media only screen and (max-width: 1024px) {
  .mobileNavActivator {
    position: fixed;
    z-index: 400;
    top: 12px;
    left: 12px;
    font-size: 30px;
    color: white;
    visibility: visible !important; } }

/* Phones: */
@media only screen and (max-height: 550px) {
  .navbar {
    display: block; }
  .SideNavbar {
    overflow: auto; }
    .SideNavbar .sideNavMain {
      height: auto;
      margin-bottom: 120px; }
      .SideNavbar .sideNavMain .flex-column {
        position: relative;
        display: block;
        margin-top: 100px;
        top: 0; }
  .NavBarFooter {
    position: relative;
    display: block;
    margin-top: 0; } }

/* User Dropdown: */
.userDropdown {
  background-color: rgba(28, 30, 32, 0.8);
  width: 100%;
  margin-left: 10px; }
  .userDropdown a {
    color: white !important; }
    .userDropdown a:hover {
      background-color: #ffbb33 !important; }

.TopNav .userDropdownToggle {
  z-index: 900;
  cursor: pointer; }
  .TopNav .userDropdownToggle .fa-caret-down {
    font-size: 15px;
    margin-left: 5px;
    transform: translateY(-50%); }
  .TopNav .userDropdownToggle svg,
  .TopNav .userDropdownToggle .userText {
    display: inline-block;
    line-height: 20px; }
    .TopNav .userDropdownToggle svg .authorityDisplay,
    .TopNav .userDropdownToggle .userText .authorityDisplay {
      font-size: 13px; }
  .TopNav .userDropdownToggle .fa.fa-user {
    font-size: 27px;
    transform: translateY(-10%); }

.TopNav {
  position: fixed;
  width: 100%;
  top: 0; }
  .TopNav .oneDigit .fa-container {
    position: absolute;
    right: 155px;
    width: auto;
    white-space: nowrap; }
  .TopNav .twoDigits .fa-container {
    position: absolute;
    right: 170px;
    width: auto;
    white-space: nowrap; }
  .TopNav .activeMainContent {
    color: #f69b1d;
    padding-left: 1%;
    font-family: Roboto;
    font-weight: 900;
    font-size: 32px;
    width: 100%; }
  .TopNav .fa-container {
    position: relative;
    display: inline-block;
    font-size: 18px;
    color: white; }
    .TopNav .fa-container .fa {
      margin-right: 10px;
      color: white;
      align-items: right;
      margin-left: 5px;
      font-size: 25px; }
    .TopNav .fa-container .fa-user {
      font-size: 20px; }
    .TopNav .fa-container .fa-caret-down {
      font-size: 15px; }
    .TopNav .fa-container:hover,
    .TopNav .fa-container:hover .fa {
      color: #fec122; }
  .TopNav .navbar-brand {
    position: fixed;
    left: 55%; }
  .TopNav .navbar {
    background-color: rgba(52, 58, 64, 0.9) !important; }
    .TopNav .navbar .topRight {
      position: absolute;
      top: 15px;
      right: 250px;
      height: 80%;
      display: inline-block; }
    .TopNav .navbar .ClockText {
      font-weight: 800;
      font-family: RobotoMono;
      letter-spacing: -1.5px;
      color: #f69b1d;
      font-size: 23px;
      position: relative;
      display: inline-block;
      margin-top: 5px; }
      .TopNav .navbar .ClockText .TT {
        margin-top: 5px;
        font-family: Roboto;
        font-weight: 600;
        top: 1px;
        position: relative; }
      .TopNav .navbar .ClockText svg {
        margin-right: 5px; }
      .TopNav .navbar .ClockText time {
        display: inline-block; }
      .TopNav .navbar .ClockText span {
        vertical-align: text-bottom;
        margin-left: 5px; }
      .TopNav .navbar .ClockText .react-clock__face {
        background-color: white; }
      .TopNav .navbar .ClockText .react-clock__hand__body,
      .TopNav .navbar .ClockText .react-clock__mark__body {
        background-color: black; }
      .TopNav .navbar .ClockText .react-clock__hour-hand__body {
        width: 2px !important; }
      .TopNav .navbar .ClockText .react-clock__minute-hand__body {
        width: 1.5px !important; }
      .TopNav .navbar .ClockText .react-clock__second-hand__body {
        background-color: #f69b1d; }
      .TopNav .navbar .ClockText .react-clock__mark {
        display: none; }
    .TopNav .navbar .NewContentBadge {
      top: 20px;
      cursor: pointer;
      color: white;
      position: absolute;
      left: 40%; }
      .TopNav .navbar .NewContentBadge.disabled {
        cursor: auto; }
      .TopNav .navbar .NewContentBadge svg {
        position: relative;
        font-size: 30px; }
      .TopNav .navbar .NewContentBadge .NewContentNum {
        position: absolute;
        left: 20px;
        top: 20px;
        background: #ffbb33;
        text-align: center;
        border-radius: 5rem;
        padding-left: 8px;
        padding-right: 8px;
        color: #212121;
        font-weight: 700;
        /*
                &:before {
                    content: "";
                    position: absolute;
                    top: 2%;
                    left: 5%;
                    width: 80%;
                    height: 80%;
                    border-radius: 50%;
                    background: radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 50%);
                    filter: blur(1px);
                    z-index: 2;
                }*/ }
        .TopNav .navbar .NewContentBadge .NewContentNum.Bounce {
          -webkit-animation: bounce 1s alternate;
                  animation: bounce 1s alternate; }
      .TopNav .navbar .NewContentBadge:hover svg {
        color: #ffbb33; }
      .TopNav .navbar .NewContentBadge:hover .NewContentNum {
        background: #f69b1d; }
  .TopNav .fa:hover {
    color: #fec122 !important; }

@-webkit-keyframes Bounce {
  from {
    transform: translateY(0px); }
  to {
    transform: translateY(-15px); } }

@keyframes Bounce {
  from {
    transform: translateY(0px); }
  to {
    transform: translateY(-15px); } }

@media only screen and (min-width: 1024px) and (max-width: 1124px) {
  /* Table */
  .TopNav .navbar .topRight {
    position: absolute;
    top: 15px;
    right: 235px;
    height: 80%;
    display: inline-block; } }

@media only screen and (min-width: 501px) and (max-width: 1024px) {
  /* Table */
  .activeMainContent {
    margin-left: 30px; } }

@media only screen and (max-width: 1024px) {
  /* Table */
  .TopNav .navbar .topRight {
    position: absolute;
    top: 15px;
    right: 40px;
    height: 80%;
    display: inline-block; }
  .TopNav .navbar .NewContentBadge {
    left: 50%; } }

@media only screen and (max-width: 1000px) {
  /* Table */
  .TopNav .oneDigit .fa-container,
  .TopNav .twoDigits .fa-container {
    right: 30px; }
  .TopNav .activeMainContent svg {
    font-size: 30px;
    margin-left: 10px; }
  .TopNav .navbar .NewContentBadge {
    left: 110px; } }

.Story .collapsibleRow td {
  vertical-align: middle; }
  .Story .collapsibleRow td:nth-child(1) {
    width: 10%;
    text-align: center; }
  .Story .collapsibleRow td:nth-child(2) {
    width: 12%;
    text-align: left; }
  .Story .collapsibleRow td:nth-child(3) {
    width: 10%;
    text-align: center; }
  .Story .collapsibleRow td:nth-child(4) {
    width: 12%;
    text-align: left; }
  .Story .collapsibleRow td:nth-child(5) {
    width: 10%;
    text-align: center; }
  .Story .collapsibleRow td:nth-child(6) {
    width: 12%;
    text-align: left; }

.Story .collapsibleRow .AboutInfo,
.Media .collapsibleRow .AboutInfo {
  text-align: left;
  margin-left: 30px;
  font-size: 0.9rem;
  line-height: 1.2;
  margin-bottom: 0.5rem; }

.Media .collapsibleRow td {
  vertical-align: middle; }
  .Media .collapsibleRow td:nth-child(1) {
    max-width: 30px;
    text-align: center; }
  .Media .collapsibleRow td:nth-child(2) {
    max-width: 50%;
    text-align: left; }
  .Media .collapsibleRow td:nth-child(3) {
    max-width: 30px;
    text-align: center; }
  .Media .collapsibleRow td:nth-child(4) {
    max-width: 50%;
    text-align: left; }

.Story .collapsibleData td,
.Media .collapsibleData td {
  font-weight: 400; }
  .Story .collapsibleData td.collapsedMediaRow,
  .Media .collapsibleData td.collapsedMediaRow {
    width: 34%;
    padding: 0; }

.Story thead tr th:nth-child(1),
.Media thead tr th:nth-child(1) {
  width: 5%; }

.Story thead tr td:nth-child(2),
.Media thead tr td:nth-child(2) {
  width: 32%; }

.Story thead tr td:nth-child(3),
.Media thead tr td:nth-child(3) {
  width: 12%; }

.Story thead tr td:nth-child(4),
.Media thead tr td:nth-child(4) {
  width: 10%; }

.Story thead tr td:nth-child(5),
.Media thead tr td:nth-child(5) {
  width: 12%; }

.Story thead tr td:nth-child(6),
.Media thead tr td:nth-child(6) {
  width: 8%; }

.Story thead tr td:nth-child(7),
.Media thead tr td:nth-child(7) {
  width: 15%; }

.Story thead th:nth-child(8),
.Media thead th:nth-child(8) {
  width: 2%;
  min-width: 40px; }

.Story thead th:nth-child(9),
.Media thead th:nth-child(9) {
  width: 2%;
  min-width: 40px; }

.Story thead th:nth-child(10),
.Media thead th:nth-child(10) {
  width: 2%;
  min-width: 40px; }

.Story tbody tr td:nth-child(3),
.Media tbody tr td:nth-child(3) {
  font-family: Assistant;
  font-weight: 400; }

.Story tbody tr td:nth-child(4),
.Media tbody tr td:nth-child(4) {
  font-family: Assistant;
  font-weight: 400; }

.Story tbody tr td:nth-child(5),
.Media tbody tr td:nth-child(5) {
  font-family: Assistant;
  font-weight: 400; }

.Story tbody tr td:nth-child(6),
.Media tbody tr td:nth-child(6) {
  font-family: Assistant;
  font-weight: 400; }

.Story tbody tr td:nth-child(7),
.Media tbody tr td:nth-child(7) {
  font-family: Assistant;
  font-weight: 400; }

.Story thead tr th:nth-child(8),
.Media thead tr th:nth-child(8) {
  width: 3%; }

.Story thead tr th:nth-child(9),
.Media thead tr th:nth-child(9) {
  width: 3%; }

@media only screen and (max-width: 800px) {
  /* Table */
  .Story thead tr td:nth-child(2),
  .Story thead tr th:nth-child(2),
  .Media thead tr td:nth-child(2),
  .Media thead tr th:nth-child(2) {
    width: 25%; }
  .Story thead tr td:nth-child(8),
  .Story thead tr th:nth-child(8),
  .Media thead tr td:nth-child(8),
  .Media thead tr th:nth-child(8) {
    width: 6%; }
  .Story thead tr td:nth-child(9),
  .Story thead tr th:nth-child(9),
  .Media thead tr td:nth-child(9),
  .Media thead tr th:nth-child(9) {
    width: 6%; } }

.EditStoryData thead tr td:nth-child(1), .EditStoryData thead tr th:nth-child(1),
.EditMediaData thead tr td:nth-child(1),
.EditMediaData thead tr th:nth-child(1) {
  width: 25%; }

.EditStoryData thead tr td:nth-child(2), .EditStoryData thead tr th:nth-child(2),
.EditMediaData thead tr td:nth-child(2),
.EditMediaData thead tr th:nth-child(2) {
  width: 12%; }

.EditStoryData thead tr td:nth-child(3), .EditStoryData thead tr th:nth-child(3),
.EditMediaData thead tr td:nth-child(3),
.EditMediaData thead tr th:nth-child(3) {
  width: 58% !important; }

.EditStoryData thead tr td:nth-child(4), .EditStoryData thead tr th:nth-child(4),
.EditMediaData thead tr td:nth-child(4),
.EditMediaData thead tr th:nth-child(4) {
  width: 5%; }

/* HubUserForm - Create/Update User/Password */

.ProductionCentre thead tr td:nth-child(1), .ProductionCentre thead tr th:nth-child(1) {
  width: 25%; }

.ProductionCentre thead tr td:nth-child(2), .ProductionCentre thead tr th:nth-child(2) {
  width: 44%; }

.ProductionCentre thead tr td:nth-child(3), .ProductionCentre thead tr th:nth-child(3) {
  width: 25%; }

.ProductionCentre thead tr td:nth-child(4), .ProductionCentre thead tr th:nth-child(4) {
  width: 3%; }

.ProductionCentre thead tr td:nth-child(5), .ProductionCentre thead tr th:nth-child(5) {
  width: 3%; }

.ProductionCentre table .data td {
  font-weight: 400; }
  .ProductionCentre table .data td:nth-child(1) {
    font-weight: 780; }

/*About:*/
.Success {
  color: #2ee62e; }

.UpdateError {
  color: red; }

.nav-tabs {
  margin-left: 1rem !important;
  border-color: rgba(224, 224, 224, 0) !important; }
  .nav-tabs .nav-link {
    color: #212121 !important; }
    .nav-tabs .nav-link.active {
      background-color: #212121 !important;
      border-color: rgba(224, 224, 224, 0) !important;
      color: #ffbb33 !important;
      border-radius: 1rem !important; }
    .nav-tabs .nav-link:hover {
      color: #f69b1d !important;
      border-color: rgba(224, 224, 224, 0) !important; }

/*for new hub page: */
.About {
  margin-right: 2.5%;
  padding-top: 20px; }

.About,
.Hub {
  padding-bottom: 10%; }
  .About .HubTabRow,
  .Hub .HubTabRow {
    margin-top: 20px; }
    .About .HubTabRow .tab-content,
    .Hub .HubTabRow .tab-content {
      padding-top: 1rem;
      padding-left: 0;
      padding-right: 1.5rem !important;
      /*create a background container for it: */
      background: #e6e6e6;
      padding: 15px;
      border-radius: 2rem;
      margin-right: 1.5rem;
      margin-left: 1rem;
      margin-top: 0.5rem; }
  .About .LogoOuterContainer,
  .Hub .LogoOuterContainer {
    max-width: 300px;
    cursor: pointer; }
    .About .LogoOuterContainer:hover .AboutLogo,
    .Hub .LogoOuterContainer:hover .AboutLogo {
      -webkit-filter: sepia(100%) saturate(350%) brightness(50%) hue-rotate(0deg);
              filter: sepia(100%) saturate(350%) brightness(50%) hue-rotate(0deg);
      opacity: 0.3; }
    .About .LogoOuterContainer:hover .LogoContainer .cam,
    .Hub .LogoOuterContainer:hover .LogoContainer .cam {
      opacity: 0.4; }
    .About .LogoOuterContainer .LogoContainer,
    .Hub .LogoOuterContainer .LogoContainer {
      display: unset;
      position: static;
      position: initial; }
      .About .LogoOuterContainer .LogoContainer .AboutLogo,
      .Hub .LogoOuterContainer .LogoContainer .AboutLogo {
        transition: opacity 0.5s, -webkit-filter 0.5s;
        transition: filter 0.5s, opacity 0.5s;
        transition: filter 0.5s, opacity 0.5s, -webkit-filter 0.5s; }
      .About .LogoOuterContainer .LogoContainer .cam,
      .Hub .LogoOuterContainer .LogoContainer .cam {
        position: absolute;
        top: 30%;
        left: 40%;
        color: white;
        background: black;
        padding: 10px;
        font-size: 45px;
        border-radius: 2rem;
        opacity: 0;
        transition: opacity 0.5s; }
  .About .LogoDescription h5,
  .About .LogoDescription p,
  .Hub .LogoDescription h5,
  .Hub .LogoDescription p {
    margin-left: 15px; }
  .About .LogoDescription h5,
  .Hub .LogoDescription h5 {
    margin-bottom: 15px; }
  .About .LogoDescription p,
  .Hub .LogoDescription p {
    line-height: 10px; }
    .About .LogoDescription p i,
    .Hub .LogoDescription p i {
      line-height: 20px; }
  .About .HubInfoCard,
  .Hub .HubInfoCard {
    margin-top: 20px; }
    .About .HubInfoCard .card-header,
    .Hub .HubInfoCard .card-header {
      padding-bottom: 0; }
      .About .HubInfoCard .card-header p,
      .Hub .HubInfoCard .card-header p {
        margin-top: -8px;
        margin-bottom: 5px; }
    .About .HubInfoCard .card-body,
    .Hub .HubInfoCard .card-body {
      padding: 0.75rem 0.25rem 0.75rem 0.3rem; }
      .About .HubInfoCard .card-body .HubCol,
      .Hub .HubInfoCard .card-body .HubCol {
        max-width: 400px; }
  .About .HubNameRow h5,
  .About .HubNameRow .form-control,
  .Hub .HubNameRow h5,
  .Hub .HubNameRow .form-control {
    margin-left: 0.5rem; }
  .About .HubNameRow .form-control,
  .Hub .HubNameRow .form-control {
    position: relative;
    display: inline-block;
    width: 70%; }
  .About .HubNameRow .updateButton,
  .Hub .HubNameRow .updateButton {
    margin: 0;
    margin-left: 10px;
    padding: 6px 10px 6px 10px; }
  .About .HubNameRow .updateLicensingButton,
  .Hub .HubNameRow .updateLicensingButton {
    float: right;
    word-spacing: 99999px;
    width: 128px;
    line-height: 14px;
    padding-top: 4px;
    padding-bottom: 4px; }
  .About .Success,
  .Hub .Success {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0; }
  .About .TableContent,
  .Hub .TableContent {
    margin-top: 0 !important; }
    .About .TableContent .editProductionGroupRow,
    .Hub .TableContent .editProductionGroupRow {
      cursor: pointer; }
      .About .TableContent .editProductionGroupRow:hover svg,
      .Hub .TableContent .editProductionGroupRow:hover svg {
        color: white; }
  .About .thickTop,
  .Hub .thickTop {
    padding-top: 0; }
  .About .pageNavRow,
  .Hub .pageNavRow {
    padding-bottom: 0; }

.About,
.Hub {
  position: relative;
  overflow-y: hidden;
  -webkit-animation: AboutEntry 1s;
          animation: AboutEntry 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  width: 100%;
  min-height: 95%; }
  .About .updateButton,
  .About .resetButton,
  .Hub .updateButton,
  .Hub .resetButton {
    background-color: #2e2e2e !important;
    margin-left: 25px;
    margin-top: 20px;
    border-radius: 0.5rem; }
    .About .updateButton:hover,
    .About .resetButton:hover,
    .Hub .updateButton:hover,
    .Hub .resetButton:hover {
      background-color: #ffbb33 !important; }
  .About .resetButton,
  .Hub .resetButton {
    padding: 6px 30px 6px 30px;
    margin-left: auto; }
  .About .AboutInfo .settingsInputSpan,
  .Hub .AboutInfo .settingsInputSpan {
    display: block;
    overflow: hidden; }
  .About .AboutInfo .settingsInput,
  .Hub .AboutInfo .settingsInput {
    padding: 0;
    height: 20px !important;
    text-align: center;
    width: 40px;
    display: inline-block;
    position: relative; }
    .About .AboutInfo .settingsInput:focus,
    .Hub .AboutInfo .settingsInput:focus {
      border-color: rgba(255, 140, 0, 0.85);
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(255, 187, 51, 0.5); }
  .About .HubInfoCard .tab-content,
  .About .StatisticCard .tab-content,
  .Hub .HubInfoCard .tab-content,
  .Hub .StatisticCard .tab-content {
    padding-bottom: 0; }
  .About .HubInfoCard .nav-tabs,
  .About .StatisticCard .nav-tabs,
  .Hub .HubInfoCard .nav-tabs,
  .Hub .StatisticCard .nav-tabs {
    border-bottom: 1px solid #e0e0e0;
    width: 113%; }
    .About .HubInfoCard .nav-tabs .nav-link.active,
    .About .StatisticCard .nav-tabs .nav-link.active,
    .Hub .HubInfoCard .nav-tabs .nav-link.active,
    .Hub .StatisticCard .nav-tabs .nav-link.active {
      background-color: #ffbb33; }
      .About .HubInfoCard .nav-tabs .nav-link.active:hover,
      .About .StatisticCard .nav-tabs .nav-link.active:hover,
      .Hub .HubInfoCard .nav-tabs .nav-link.active:hover,
      .Hub .StatisticCard .nav-tabs .nav-link.active:hover {
        color: #212121; }
    .About .HubInfoCard .nav-tabs a,
    .About .StatisticCard .nav-tabs a,
    .Hub .HubInfoCard .nav-tabs a,
    .Hub .StatisticCard .nav-tabs a {
      color: #212121; }
      .About .HubInfoCard .nav-tabs a:hover,
      .About .StatisticCard .nav-tabs a:hover,
      .Hub .HubInfoCard .nav-tabs a:hover,
      .Hub .StatisticCard .nav-tabs a:hover {
        color: #ffbb33; }
  .About .HubInfoCard,
  .Hub .HubInfoCard {
    /* padding-top:30px; */
    z-index: 100;
    margin-top: 20px; }
    .About .HubInfoCard .Success,
    .Hub .HubInfoCard .Success {
      margin-left: 25px; }
    .About .HubInfoCard .UpdateError,
    .Hub .HubInfoCard .UpdateError {
      margin-left: 25px; }
    .About .HubInfoCard .col-8,
    .Hub .HubInfoCard .col-8 {
      z-index: 100; }
    .About .HubInfoCard .AboutLogoContainer,
    .Hub .HubInfoCard .AboutLogoContainer {
      z-index: 100; }
    .About .HubInfoCard .tab-content,
    .Hub .HubInfoCard .tab-content {
      margin-left: 1rem;
      margin-right: 1rem; }
      .About .HubInfoCard .tab-content #uncontrolled-tab-example-tabpane-clean-up,
      .Hub .HubInfoCard .tab-content #uncontrolled-tab-example-tabpane-clean-up {
        width: 140%; }
    .About .HubInfoCard .subSettingContainer,
    .Hub .HubInfoCard .subSettingContainer {
      overflow: auto;
      margin-bottom: 20px;
      margin-left: 20px; }
    .About .HubInfoCard .AboutInfo,
    .Hub .HubInfoCard .AboutInfo {
      margin-left: 0; }
  .About .StatisticCard,
  .Hub .StatisticCard {
    /* padding-top:30px; */
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    margin-top: 20px;
    margin-right: 30px;
    opacity: 0; }
    .About .StatisticCard .Success,
    .About .StatisticCard .UpdateError,
    .Hub .StatisticCard .Success,
    .Hub .StatisticCard .UpdateError {
      margin-top: 10px;
      margin-bottom: 0px;
      width: 150%; }
    .About .StatisticCard .tab-pane,
    .Hub .StatisticCard .tab-pane {
      margin-left: -10px; }
    .About .StatisticCard ul,
    .Hub .StatisticCard ul {
      list-style-type: none; }
    .About .StatisticCard .subTitle,
    .Hub .StatisticCard .subTitle {
      font-weight: 500; }
    .About .StatisticCard .chartIcon,
    .Hub .StatisticCard .chartIcon {
      position: relative;
      border-radius: 50%;
      width: 90%;
      height: auto;
      padding-top: 75%;
      margin-top: 25px;
      background: #ffbb33; }
    .About .StatisticCard .globeCol,
    .Hub .StatisticCard .globeCol {
      display: table; }
    .About .StatisticCard .btn,
    .Hub .StatisticCard .btn {
      background-color: #212121 !important;
      margin-top: 15px;
      margin-left: -3px; }
      .About .StatisticCard .btn:hover,
      .Hub .StatisticCard .btn:hover {
        background-color: #ffbb33 !important; }
    .About .StatisticCard .deleteBtn,
    .Hub .StatisticCard .deleteBtn {
      position: absolute;
      margin-left: 5px; }
    .About .StatisticCard .addLocButton,
    .Hub .StatisticCard .addLocButton {
      margin-left: 5px;
      cursor: pointer;
      font-size: 20px;
      margin-top: 2%;
      margin-bottom: 1%; }
      .About .StatisticCard .addLocButton:hover,
      .Hub .StatisticCard .addLocButton:hover {
        color: #ffbb33; }
      .About .StatisticCard .addLocButton.remove,
      .Hub .StatisticCard .addLocButton.remove {
        position: relative;
        display: inline; }
        .About .StatisticCard .addLocButton.remove.disabled,
        .Hub .StatisticCard .addLocButton.remove.disabled {
          pointer-events: none;
          opacity: 0.3; }
  .About .card,
  .Hub .card {
    border-radius: 0.5rem; }
  .About .AboutLogoContainer,
  .About .AboutStatsLogo,
  .Hub .AboutLogoContainer,
  .Hub .AboutStatsLogo {
    margin-top: 20px;
    width: 90%;
    max-width: 200px;
    position: relative; }
  .About .geoLatInput,
  .About .geoLonInput,
  .Hub .geoLatInput,
  .Hub .geoLonInput {
    font-size: 10px !important;
    height: 100% !important;
    width: 43%;
    margin-bottom: 3px;
    float: left;
    position: relative;
    display: inline-block; }
  .About .geoLatInput,
  .Hub .geoLatInput {
    margin-right: 2%; }
  .About .geoInputs,
  .Hub .geoInputs {
    width: 140%; }
  .About .AboutLogo,
  .Hub .AboutLogo {
    width: 100%; }
  .About .errorStatus,
  .Hub .errorStatus {
    color: yellow;
    font-weight: 900;
    text-align: center;
    margin-top: 10px; }
  .About .custom-file-label,
  .About .custom-file-label::after,
  .Hub .custom-file-label,
  .Hub .custom-file-label::after {
    height: 33px !important;
    font-size: 14px; }
  .About .faContainer,
  .Hub .faContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 20px;
    max-height: 200px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 1.5rem;
    padding: 5px; }
    .About .faContainer svg,
    .Hub .faContainer svg {
      color: #e0dfdf; }
    .About .faContainer:hover svg,
    .Hub .faContainer:hover svg {
      color: #ffbb33; }
  .About .AboutSettings,
  .Hub .AboutSettings {
    position: relative;
    z-index: 150;
    width: 100%; }
    .About .AboutSettings .card-body,
    .Hub .AboutSettings .card-body {
      padding-left: 0 !important; }
    .About .AboutSettings .AboutSettingsInner,
    .Hub .AboutSettings .AboutSettingsInner {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 10px; }
      .About .AboutSettings .AboutSettingsInner .form-control,
      .Hub .AboutSettings .AboutSettingsInner .form-control {
        margin-right: 15px;
        background: rgba(0, 0, 0, 0.35);
        color: white !important; }
        .About .AboutSettings .AboutSettingsInner .form-control:focus,
        .Hub .AboutSettings .AboutSettingsInner .form-control:focus {
          border-color: #ffbb33;
          box-shadow: 0 0 0 0.2rem rgba(255, 187, 51, 0.45); }
        .About .AboutSettings .AboutSettingsInner .form-control::-webkit-input-placeholder, .Hub .AboutSettings .AboutSettingsInner .form-control::-webkit-input-placeholder {
          color: #cccccc !important; }
        .About .AboutSettings .AboutSettingsInner .form-control:-ms-input-placeholder, .Hub .AboutSettings .AboutSettingsInner .form-control:-ms-input-placeholder {
          color: #cccccc !important; }
        .About .AboutSettings .AboutSettingsInner .form-control::-ms-input-placeholder, .Hub .AboutSettings .AboutSettingsInner .form-control::-ms-input-placeholder {
          color: #cccccc !important; }
        .About .AboutSettings .AboutSettingsInner .form-control::placeholder,
        .Hub .AboutSettings .AboutSettingsInner .form-control::placeholder {
          color: #cccccc !important; }
    .About .AboutSettings .btn-dark,
    .Hub .AboutSettings .btn-dark {
      display: block;
      margin: auto;
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px; }
      .About .AboutSettings .btn-dark:hover,
      .Hub .AboutSettings .btn-dark:hover {
        background-color: #ffbb33 !important; }
    .About .AboutSettings .card,
    .Hub .AboutSettings .card {
      background-color: rgba(0, 0, 0, 0.55);
      color: white; }
  .About .card-title,
  .Hub .card-title {
    padding-bottom: 15px;
    font-size: 27px; }
  .About .AboutInfo,
  .Hub .AboutInfo {
    margin-left: 0;
    margin-bottom: 0.2rem;
    padding-left: 0;
    padding-right: 1% !important; }

.About {
  min-height: 110%;
  margin-bottom: 40px; }
  .About .col-12 {
    padding-right: 15px !important; }
    .About .col-12 .card-body {
      padding-right: 1.25rem !important; }
  .About .AboutLogo {
    max-width: 500px;
    margin: auto;
    position: relative;
    display: block; }

@-webkit-keyframes AboutEntry {
  0% {
    background-color: white; }
  100% {
    background-color: #d0d0d0; } }

@keyframes AboutEntry {
  0% {
    background-color: white; }
  100% {
    background-color: #d0d0d0; } }

@-webkit-keyframes AboutButtonEntry {
  0% {
    background-color: rgba(33, 33, 33, 0);
    opacity: 0; }
  30% {
    background-color: rgba(33, 33, 33, 0);
    opacity: 0; }
  100% {
    background-color: #212121;
    opacity: 1; } }

@keyframes AboutButtonEntry {
  0% {
    background-color: rgba(33, 33, 33, 0);
    opacity: 0; }
  30% {
    background-color: rgba(33, 33, 33, 0);
    opacity: 0; }
  100% {
    background-color: #212121;
    opacity: 1; } }

@-webkit-keyframes AboutCardEntry {
  0% {
    margin-top: 100px;
    opacity: 0; }
  100% {
    margin-top: 15px;
    opacity: 1; } }

@keyframes AboutCardEntry {
  0% {
    margin-top: 100px;
    opacity: 0; }
  100% {
    margin-top: 15px;
    opacity: 1; } }

@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .About {
    width: 100%; } }

@media only screen and (min-width: 1280px) and (max-width: 2050px) {
  .purgeDesc {
    width: 72%; } }

/*Modal styling: */
.modal {
  pointer-events: none; }
  .modal .modal-dialog {
    pointer-events: all; }

.modal-content {
  border-radius: 1.4rem !important; }
  .modal-content .modal-header {
    border-top-left-radius: 1.25rem !important;
    border-top-right-radius: 1.25rem !important;
    /*Modal close button: */ }
    .modal-content .modal-header .modal-title {
      color: #f69b1d;
      font-family: Roboto;
      font-weight: 900;
      font-size: 38px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px; }
    .modal-content .modal-header .close {
      color: white;
      opacity: 1; }
      .modal-content .modal-header .close:hover {
        color: #f69b1d !important;
        opacity: 1 !important;
        text-shadow: none !important; }
  .modal-content .modal-body {
    /* for upload: */ }
    .modal-content .modal-body .formDropdown .btn {
      text-transform: none; }
    .modal-content .modal-body .btn {
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 8px;
      margin-bottom: 10px;
      background-color: #383838 !important;
      color: white !important;
      font-size: 15px; }
      .modal-content .modal-body .btn:hover {
        color: #ffffff;
        background-color: #f69b1d !important; }
      .modal-content .modal-body .btn.Ripple-parent {
        float: none !important;
        margin-right: auto !important;
        margin-left: auto !important; }
      .modal-content .modal-body .btn.wrongDataType {
        background-color: red !important; }
        .modal-content .modal-body .btn.wrongDataType:hover {
          background-color: red !important; }
    .modal-content .modal-body .uploadedFileDisplay {
      margin-top: 15px; }
    .modal-content .modal-body .input-group:hover .custom-file-label {
      box-shadow: 0 0 15px #ffbb33; }

.additionalSettingsTooltip {
  pointer-events: none !important;
  top: -50px !important; }

.CreateNewStory .collapsableDiv,
.UploadMediaFile .collapsableDiv {
  position: absolute;
  min-width: calc(100% - 474px);
  display: inline-block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  float: left;
  overflow: hidden;
  height: 700px;
  margin-left: 20px; }

.CreateNewStory .StoryDetailsForm .storyKeyword, .CreateNewStory .StoryDetailsForm .elementKeyword,
.CreateNewStory .ElementDetailsForm .storyKeyword,
.CreateNewStory .ElementDetailsForm .elementKeyword,
.UploadMediaFile .StoryDetailsForm .storyKeyword,
.UploadMediaFile .StoryDetailsForm .elementKeyword,
.UploadMediaFile .ElementDetailsForm .storyKeyword,
.UploadMediaFile .ElementDetailsForm .elementKeyword {
  margin-bottom: 20px;
  margin-top: -5px; }

.CreateNewStory .StoryDetailsForm .multiValsSelectorWrapper,
.CreateNewStory .ElementDetailsForm .multiValsSelectorWrapper,
.UploadMediaFile .StoryDetailsForm .multiValsSelectorWrapper,
.UploadMediaFile .ElementDetailsForm .multiValsSelectorWrapper {
  margin-top: 0;
  margin-bottom: 0; }

.CreateNewStory .StoryDetailsForm .disablePopup,
.CreateNewStory .ElementDetailsForm .disablePopup,
.UploadMediaFile .StoryDetailsForm .disablePopup,
.UploadMediaFile .ElementDetailsForm .disablePopup {
  margin-top: 0;
  display: inline-block; }
  .CreateNewStory .StoryDetailsForm .disablePopup .formDropdown,
  .CreateNewStory .ElementDetailsForm .disablePopup .formDropdown,
  .UploadMediaFile .StoryDetailsForm .disablePopup .formDropdown,
  .UploadMediaFile .ElementDetailsForm .disablePopup .formDropdown {
    margin-top: 0;
    display: inline-block; }
    .CreateNewStory .StoryDetailsForm .disablePopup .formDropdown .dropdown-menu,
    .CreateNewStory .ElementDetailsForm .disablePopup .formDropdown .dropdown-menu,
    .UploadMediaFile .StoryDetailsForm .disablePopup .formDropdown .dropdown-menu,
    .UploadMediaFile .ElementDetailsForm .disablePopup .formDropdown .dropdown-menu {
      margin-top: 0; }

.CreateNewStory .StoryDetailsForm div,
.CreateNewStory .StoryDetailsForm textarea,
.CreateNewStory .StoryDetailsForm input,
.CreateNewStory .ElementDetailsForm div,
.CreateNewStory .ElementDetailsForm textarea,
.CreateNewStory .ElementDetailsForm input,
.UploadMediaFile .StoryDetailsForm div,
.UploadMediaFile .StoryDetailsForm textarea,
.UploadMediaFile .StoryDetailsForm input,
.UploadMediaFile .ElementDetailsForm div,
.UploadMediaFile .ElementDetailsForm textarea,
.UploadMediaFile .ElementDetailsForm input {
  margin-top: 10px;
  margin-bottom: 10px; }
  .CreateNewStory .StoryDetailsForm div .filterCountDisplay,
  .CreateNewStory .StoryDetailsForm textarea .filterCountDisplay,
  .CreateNewStory .StoryDetailsForm input .filterCountDisplay,
  .CreateNewStory .ElementDetailsForm div .filterCountDisplay,
  .CreateNewStory .ElementDetailsForm textarea .filterCountDisplay,
  .CreateNewStory .ElementDetailsForm input .filterCountDisplay,
  .UploadMediaFile .StoryDetailsForm div .filterCountDisplay,
  .UploadMediaFile .StoryDetailsForm textarea .filterCountDisplay,
  .UploadMediaFile .StoryDetailsForm input .filterCountDisplay,
  .UploadMediaFile .ElementDetailsForm div .filterCountDisplay,
  .UploadMediaFile .ElementDetailsForm textarea .filterCountDisplay,
  .UploadMediaFile .ElementDetailsForm input .filterCountDisplay {
    margin-top: 0; }
    .CreateNewStory .StoryDetailsForm div .filterCountDisplay .filterCount,
    .CreateNewStory .StoryDetailsForm textarea .filterCountDisplay .filterCount,
    .CreateNewStory .StoryDetailsForm input .filterCountDisplay .filterCount,
    .CreateNewStory .ElementDetailsForm div .filterCountDisplay .filterCount,
    .CreateNewStory .ElementDetailsForm textarea .filterCountDisplay .filterCount,
    .CreateNewStory .ElementDetailsForm input .filterCountDisplay .filterCount,
    .UploadMediaFile .StoryDetailsForm div .filterCountDisplay .filterCount,
    .UploadMediaFile .StoryDetailsForm textarea .filterCountDisplay .filterCount,
    .UploadMediaFile .StoryDetailsForm input .filterCountDisplay .filterCount,
    .UploadMediaFile .ElementDetailsForm div .filterCountDisplay .filterCount,
    .UploadMediaFile .ElementDetailsForm textarea .filterCountDisplay .filterCount,
    .UploadMediaFile .ElementDetailsForm input .filterCountDisplay .filterCount {
      display: block; }
  .CreateNewStory .StoryDetailsForm div .filterDropdownToggle,
  .CreateNewStory .StoryDetailsForm textarea .filterDropdownToggle,
  .CreateNewStory .StoryDetailsForm input .filterDropdownToggle,
  .CreateNewStory .ElementDetailsForm div .filterDropdownToggle,
  .CreateNewStory .ElementDetailsForm textarea .filterDropdownToggle,
  .CreateNewStory .ElementDetailsForm input .filterDropdownToggle,
  .UploadMediaFile .StoryDetailsForm div .filterDropdownToggle,
  .UploadMediaFile .StoryDetailsForm textarea .filterDropdownToggle,
  .UploadMediaFile .StoryDetailsForm input .filterDropdownToggle,
  .UploadMediaFile .ElementDetailsForm div .filterDropdownToggle,
  .UploadMediaFile .ElementDetailsForm textarea .filterDropdownToggle,
  .UploadMediaFile .ElementDetailsForm input .filterDropdownToggle {
    height: 40px; }
  .CreateNewStory .StoryDetailsForm div .MuiInputBase-input,
  .CreateNewStory .StoryDetailsForm textarea .MuiInputBase-input,
  .CreateNewStory .StoryDetailsForm input .MuiInputBase-input,
  .CreateNewStory .ElementDetailsForm div .MuiInputBase-input,
  .CreateNewStory .ElementDetailsForm textarea .MuiInputBase-input,
  .CreateNewStory .ElementDetailsForm input .MuiInputBase-input,
  .UploadMediaFile .StoryDetailsForm div .MuiInputBase-input,
  .UploadMediaFile .StoryDetailsForm textarea .MuiInputBase-input,
  .UploadMediaFile .StoryDetailsForm input .MuiInputBase-input,
  .UploadMediaFile .ElementDetailsForm div .MuiInputBase-input,
  .UploadMediaFile .ElementDetailsForm textarea .MuiInputBase-input,
  .UploadMediaFile .ElementDetailsForm input .MuiInputBase-input {
    margin-bottom: 0; }
  .CreateNewStory .StoryDetailsForm div .filterDropdown div,
  .CreateNewStory .StoryDetailsForm textarea .filterDropdown div,
  .CreateNewStory .StoryDetailsForm input .filterDropdown div,
  .CreateNewStory .ElementDetailsForm div .filterDropdown div,
  .CreateNewStory .ElementDetailsForm textarea .filterDropdown div,
  .CreateNewStory .ElementDetailsForm input .filterDropdown div,
  .UploadMediaFile .StoryDetailsForm div .filterDropdown div,
  .UploadMediaFile .StoryDetailsForm textarea .filterDropdown div,
  .UploadMediaFile .StoryDetailsForm input .filterDropdown div,
  .UploadMediaFile .ElementDetailsForm div .filterDropdown div,
  .UploadMediaFile .ElementDetailsForm textarea .filterDropdown div,
  .UploadMediaFile .ElementDetailsForm input .filterDropdown div {
    margin-bottom: 0; }

.CreateNewStory .StoryDetailsForm .filterDropdown,
.CreateNewStory .ElementDetailsForm .filterDropdown,
.UploadMediaFile .StoryDetailsForm .filterDropdown,
.UploadMediaFile .ElementDetailsForm .filterDropdown {
  margin-top: 0; }
  .CreateNewStory .StoryDetailsForm .filterDropdown .filterDropdownMenu,
  .CreateNewStory .ElementDetailsForm .filterDropdown .filterDropdownMenu,
  .UploadMediaFile .StoryDetailsForm .filterDropdown .filterDropdownMenu,
  .UploadMediaFile .ElementDetailsForm .filterDropdown .filterDropdownMenu {
    margin-top: 0; }
    .CreateNewStory .StoryDetailsForm .filterDropdown .filterDropdownMenu div,
    .CreateNewStory .StoryDetailsForm .filterDropdown .filterDropdownMenu input,
    .CreateNewStory .ElementDetailsForm .filterDropdown .filterDropdownMenu div,
    .CreateNewStory .ElementDetailsForm .filterDropdown .filterDropdownMenu input,
    .UploadMediaFile .StoryDetailsForm .filterDropdown .filterDropdownMenu div,
    .UploadMediaFile .StoryDetailsForm .filterDropdown .filterDropdownMenu input,
    .UploadMediaFile .ElementDetailsForm .filterDropdown .filterDropdownMenu div,
    .UploadMediaFile .ElementDetailsForm .filterDropdown .filterDropdownMenu input {
      margin-top: 0; }
    .CreateNewStory .StoryDetailsForm .filterDropdown .filterDropdownMenu .card-body,
    .CreateNewStory .ElementDetailsForm .filterDropdown .filterDropdownMenu .card-body,
    .UploadMediaFile .StoryDetailsForm .filterDropdown .filterDropdownMenu .card-body,
    .UploadMediaFile .ElementDetailsForm .filterDropdown .filterDropdownMenu .card-body {
      padding-left: 0;
      padding-right: 0; }
    .CreateNewStory .StoryDetailsForm .filterDropdown .filterDropdownMenu .col-12,
    .CreateNewStory .ElementDetailsForm .filterDropdown .filterDropdownMenu .col-12,
    .UploadMediaFile .StoryDetailsForm .filterDropdown .filterDropdownMenu .col-12,
    .UploadMediaFile .ElementDetailsForm .filterDropdown .filterDropdownMenu .col-12 {
      padding-left: 0; }
    .CreateNewStory .StoryDetailsForm .filterDropdown .filterDropdownMenu .form-check,
    .CreateNewStory .ElementDetailsForm .filterDropdown .filterDropdownMenu .form-check,
    .UploadMediaFile .StoryDetailsForm .filterDropdown .filterDropdownMenu .form-check,
    .UploadMediaFile .ElementDetailsForm .filterDropdown .filterDropdownMenu .form-check {
      display: block;
      padding-left: 0;
      width: 100%; }
      .CreateNewStory .StoryDetailsForm .filterDropdown .filterDropdownMenu .form-check .form-check-label,
      .CreateNewStory .ElementDetailsForm .filterDropdown .filterDropdownMenu .form-check .form-check-label,
      .UploadMediaFile .StoryDetailsForm .filterDropdown .filterDropdownMenu .form-check .form-check-label,
      .UploadMediaFile .ElementDetailsForm .filterDropdown .filterDropdownMenu .form-check .form-check-label {
        padding-left: 35px; }
        .CreateNewStory .StoryDetailsForm .filterDropdown .filterDropdownMenu .form-check .form-check-label::before,
        .CreateNewStory .ElementDetailsForm .filterDropdown .filterDropdownMenu .form-check .form-check-label::before,
        .UploadMediaFile .StoryDetailsForm .filterDropdown .filterDropdownMenu .form-check .form-check-label::before,
        .UploadMediaFile .ElementDetailsForm .filterDropdown .filterDropdownMenu .form-check .form-check-label::before {
          top: 0; }

.CreateNewStory .StoryDetailsForm textarea,
.CreateNewStory .ElementDetailsForm textarea,
.UploadMediaFile .StoryDetailsForm textarea,
.UploadMediaFile .ElementDetailsForm textarea {
  min-height: 320px; }

.CreateNewStory .StoryDetailsForm .ImportantInfoRow,
.CreateNewStory .ElementDetailsForm .ImportantInfoRow,
.UploadMediaFile .StoryDetailsForm .ImportantInfoRow,
.UploadMediaFile .ElementDetailsForm .ImportantInfoRow {
  margin-top: -15px; }

.CreateNewStory .StoryDetailsForm .LargeCheckbox,
.CreateNewStory .ElementDetailsForm .LargeCheckbox,
.UploadMediaFile .StoryDetailsForm .LargeCheckbox,
.UploadMediaFile .ElementDetailsForm .LargeCheckbox {
  margin-top: -2px; }
  .CreateNewStory .StoryDetailsForm .LargeCheckbox .formTitle,
  .CreateNewStory .ElementDetailsForm .LargeCheckbox .formTitle,
  .UploadMediaFile .StoryDetailsForm .LargeCheckbox .formTitle,
  .UploadMediaFile .ElementDetailsForm .LargeCheckbox .formTitle {
    text-align: center;
    margin-bottom: 10px; }
  .CreateNewStory .StoryDetailsForm .LargeCheckbox .filterGroupChild,
  .CreateNewStory .ElementDetailsForm .LargeCheckbox .filterGroupChild,
  .UploadMediaFile .StoryDetailsForm .LargeCheckbox .filterGroupChild,
  .UploadMediaFile .ElementDetailsForm .LargeCheckbox .filterGroupChild {
    margin-top: 0; }
  .CreateNewStory .StoryDetailsForm .LargeCheckbox .form-check .form-check-label,
  .CreateNewStory .ElementDetailsForm .LargeCheckbox .form-check .form-check-label,
  .UploadMediaFile .StoryDetailsForm .LargeCheckbox .form-check .form-check-label,
  .UploadMediaFile .ElementDetailsForm .LargeCheckbox .form-check .form-check-label {
    margin-top: -15px; }
  .CreateNewStory .StoryDetailsForm .LargeCheckbox .checkboxContainer,
  .CreateNewStory .ElementDetailsForm .LargeCheckbox .checkboxContainer,
  .UploadMediaFile .StoryDetailsForm .LargeCheckbox .checkboxContainer,
  .UploadMediaFile .ElementDetailsForm .LargeCheckbox .checkboxContainer {
    padding-left: 0; }
    .CreateNewStory .StoryDetailsForm .LargeCheckbox .checkboxContainer .checkmark,
    .CreateNewStory .ElementDetailsForm .LargeCheckbox .checkboxContainer .checkmark,
    .UploadMediaFile .StoryDetailsForm .LargeCheckbox .checkboxContainer .checkmark,
    .UploadMediaFile .ElementDetailsForm .LargeCheckbox .checkboxContainer .checkmark {
      margin: auto;
      position: relative;
      display: block; }

.CreateNewStory .StoryDetailsForm .custom-file,
.CreateNewStory .ElementDetailsForm .custom-file,
.UploadMediaFile .StoryDetailsForm .custom-file,
.UploadMediaFile .ElementDetailsForm .custom-file {
  margin-top: 0; }

.CreateNewStory .StoryDetailsForm .col-10,
.CreateNewStory .ElementDetailsForm .col-10,
.UploadMediaFile .StoryDetailsForm .col-10,
.UploadMediaFile .ElementDetailsForm .col-10 {
  padding-left: 0; }

.CreateNewStory .StoryDetailsForm .col-2,
.CreateNewStory .ElementDetailsForm .col-2,
.UploadMediaFile .StoryDetailsForm .col-2,
.UploadMediaFile .ElementDetailsForm .col-2 {
  padding-left: 5px; }

.CreateNewStory .StoryDetailsForm .rowEditor,
.CreateNewStory .ElementDetailsForm .rowEditor,
.UploadMediaFile .StoryDetailsForm .rowEditor,
.UploadMediaFile .ElementDetailsForm .rowEditor {
  margin-left: 280px;
  margin-top: 0px; }
  .CreateNewStory .StoryDetailsForm .rowEditor .arrow,
  .CreateNewStory .ElementDetailsForm .rowEditor .arrow,
  .UploadMediaFile .StoryDetailsForm .rowEditor .arrow,
  .UploadMediaFile .ElementDetailsForm .rowEditor .arrow {
    left: 9px;
    top: -28px; }
  .CreateNewStory .StoryDetailsForm .rowEditor .rowEditorContent,
  .CreateNewStory .ElementDetailsForm .rowEditor .rowEditorContent,
  .UploadMediaFile .StoryDetailsForm .rowEditor .rowEditorContent,
  .UploadMediaFile .ElementDetailsForm .rowEditor .rowEditorContent {
    margin-top: 0; }
  .CreateNewStory .StoryDetailsForm .rowEditor .dropdown-menu,
  .CreateNewStory .ElementDetailsForm .rowEditor .dropdown-menu,
  .UploadMediaFile .StoryDetailsForm .rowEditor .dropdown-menu,
  .UploadMediaFile .ElementDetailsForm .rowEditor .dropdown-menu {
    margin-top: 0;
    padding: 0;
    width: 95%; }

.CreateNewStory .StoryDetailsForm .categoryBadge,
.CreateNewStory .ElementDetailsForm .categoryBadge,
.UploadMediaFile .StoryDetailsForm .categoryBadge,
.UploadMediaFile .ElementDetailsForm .categoryBadge {
  padding: 5px;
  background-color: #e6e6e6;
  display: inline-block;
  border-radius: 2rem;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 5px;
  margin-right: 10px; }
  .CreateNewStory .StoryDetailsForm .categoryBadge .deleteIcon,
  .CreateNewStory .ElementDetailsForm .categoryBadge .deleteIcon,
  .UploadMediaFile .StoryDetailsForm .categoryBadge .deleteIcon,
  .UploadMediaFile .ElementDetailsForm .categoryBadge .deleteIcon {
    margin-left: 5px;
    cursor: pointer; }
    .CreateNewStory .StoryDetailsForm .categoryBadge .deleteIcon :hover,
    .CreateNewStory .ElementDetailsForm .categoryBadge .deleteIcon :hover,
    .UploadMediaFile .StoryDetailsForm .categoryBadge .deleteIcon :hover,
    .UploadMediaFile .ElementDetailsForm .categoryBadge .deleteIcon :hover {
      color: #f69b1d; }

.CreateNewStory .StoryDetailsForm svg,
.CreateNewStory .ElementDetailsForm svg,
.UploadMediaFile .StoryDetailsForm svg,
.UploadMediaFile .ElementDetailsForm svg {
  display: inline-block;
  cursor: pointer; }
  .CreateNewStory .StoryDetailsForm svg:hover,
  .CreateNewStory .ElementDetailsForm svg:hover,
  .UploadMediaFile .StoryDetailsForm svg:hover,
  .UploadMediaFile .ElementDetailsForm svg:hover {
    color: #f69b1d; }

.CreateNewStory .modal-content .modal-body,
.UploadMediaFile .modal-content .modal-body {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }
  .CreateNewStory .modal-content .modal-body .burli-metadata-form,
  .UploadMediaFile .modal-content .modal-body .burli-metadata-form {
    margin-top: 0 !important; }
    .CreateNewStory .modal-content .modal-body .burli-metadata-form .structureRow,
    .UploadMediaFile .modal-content .modal-body .burli-metadata-form .structureRow {
      padding-top: 5px; }
  .CreateNewStory .modal-content .modal-body .ExpandDiv,
  .CreateNewStory .modal-content .modal-body .UnexpandDiv,
  .UploadMediaFile .modal-content .modal-body .ExpandDiv,
  .UploadMediaFile .modal-content .modal-body .UnexpandDiv {
    position: absolute;
    height: 90%;
    width: 65px;
    border-radius: 2rem;
    top: 0%;
    cursor: pointer; }
    .CreateNewStory .modal-content .modal-body .ExpandDiv #arrowAnim,
    .CreateNewStory .modal-content .modal-body .UnexpandDiv #arrowAnim,
    .UploadMediaFile .modal-content .modal-body .ExpandDiv #arrowAnim,
    .UploadMediaFile .modal-content .modal-body .UnexpandDiv #arrowAnim {
      position: absolute;
      top: 45%;
      margin-left: 5%;
      margin: auto; }
      .CreateNewStory .modal-content .modal-body .ExpandDiv #arrowAnim .arrow,
      .CreateNewStory .modal-content .modal-body .UnexpandDiv #arrowAnim .arrow,
      .UploadMediaFile .modal-content .modal-body .ExpandDiv #arrowAnim .arrow,
      .UploadMediaFile .modal-content .modal-body .UnexpandDiv #arrowAnim .arrow {
        border-color: #757575 transparent transparent #757575; }
    .CreateNewStory .modal-content .modal-body .ExpandDiv:hover,
    .CreateNewStory .modal-content .modal-body .UnexpandDiv:hover,
    .UploadMediaFile .modal-content .modal-body .ExpandDiv:hover,
    .UploadMediaFile .modal-content .modal-body .UnexpandDiv:hover {
      background: linear-gradient(90deg, rgba(255, 187, 51, 0) 0%, rgba(255, 187, 51, 0.4) 30%, rgba(255, 187, 51, 0.8) 50%, rgba(255, 187, 51, 0.4) 70%, rgba(255, 187, 51, 0) 100%); }
      .CreateNewStory .modal-content .modal-body .ExpandDiv:hover #arrowAnim .arrow,
      .CreateNewStory .modal-content .modal-body .UnexpandDiv:hover #arrowAnim .arrow,
      .UploadMediaFile .modal-content .modal-body .ExpandDiv:hover #arrowAnim .arrow,
      .UploadMediaFile .modal-content .modal-body .UnexpandDiv:hover #arrowAnim .arrow {
        border-color: #f69b1d transparent transparent #f69b1d; }
    .CreateNewStory .modal-content .modal-body .ExpandDiv.disabledWithTooltip:hover,
    .CreateNewStory .modal-content .modal-body .UnexpandDiv.disabledWithTooltip:hover,
    .UploadMediaFile .modal-content .modal-body .ExpandDiv.disabledWithTooltip:hover,
    .UploadMediaFile .modal-content .modal-body .UnexpandDiv.disabledWithTooltip:hover {
      background: linear-gradient(90deg, rgba(33, 33, 33, 0) 0%, rgba(33, 33, 33, 0.4) 30%, rgba(33, 33, 33, 0.4) 70%, rgba(33, 33, 33, 0) 100%); }
      .CreateNewStory .modal-content .modal-body .ExpandDiv.disabledWithTooltip:hover #arrowAnim,
      .CreateNewStory .modal-content .modal-body .UnexpandDiv.disabledWithTooltip:hover #arrowAnim,
      .UploadMediaFile .modal-content .modal-body .ExpandDiv.disabledWithTooltip:hover #arrowAnim,
      .UploadMediaFile .modal-content .modal-body .UnexpandDiv.disabledWithTooltip:hover #arrowAnim {
        position: absolute;
        top: 45%;
        margin-left: 5%;
        margin: auto; }
        .CreateNewStory .modal-content .modal-body .ExpandDiv.disabledWithTooltip:hover #arrowAnim .arrow,
        .CreateNewStory .modal-content .modal-body .UnexpandDiv.disabledWithTooltip:hover #arrowAnim .arrow,
        .UploadMediaFile .modal-content .modal-body .ExpandDiv.disabledWithTooltip:hover #arrowAnim .arrow,
        .UploadMediaFile .modal-content .modal-body .UnexpandDiv.disabledWithTooltip:hover #arrowAnim .arrow {
          border-color: #757575 transparent transparent #757575; }
  .CreateNewStory .modal-content .modal-body .ExpandDiv,
  .UploadMediaFile .modal-content .modal-body .ExpandDiv {
    background: linear-gradient(90deg, rgba(33, 33, 33, 0) 0%, rgba(33, 33, 33, 0.4) 30%, rgba(33, 33, 33, 0.4) 70%, rgba(33, 33, 33, 0) 100%); }
  .CreateNewStory .modal-content .modal-body .md-form,
  .UploadMediaFile .modal-content .modal-body .md-form {
    margin: 0; }
  .CreateNewStory .modal-content .modal-body form,
  .UploadMediaFile .modal-content .modal-body form {
    /*div,
            textarea,
            input {
                margin-bottom: 1rem;
                margin-top: 0.5rem;
            }
            &.burli-metadata-form {
                div,
                textarea,
                input {
                    margin-bottom: 0;
                    margin-top: 0;
                }
                .thickTop {
                    padding: 0;
                }
            }*/ }
    .CreateNewStory .modal-content .modal-body form.StoryDetailsForm, .CreateNewStory .modal-content .modal-body form.ElementDetailsForm,
    .UploadMediaFile .modal-content .modal-body form.StoryDetailsForm,
    .UploadMediaFile .modal-content .modal-body form.ElementDetailsForm {
      float: left;
      width: 430px;
      max-width: 430px; }
  .CreateNewStory .modal-content .modal-body .HorizontalMetadataCol,
  .UploadMediaFile .modal-content .modal-body .HorizontalMetadataCol {
    display: inline-block;
    float: left;
    position: relative;
    margin-left: 30px;
    /* width: calc(100% - 500px); set a static length first by calculating the width first using JS, after it's expanded set to calc (100% - 500px) */
    top: 0;
    position: absolute; }

.CreateNewStory .HubUserForm,
.UploadMediaFile .HubUserForm {
  margin-left: 15px;
  padding: 0; }
  .CreateNewStory .HubUserForm .TableToolbarTop .col-4,
  .CreateNewStory .HubUserForm .filterSearchBar,
  .UploadMediaFile .HubUserForm .TableToolbarTop .col-4,
  .UploadMediaFile .HubUserForm .filterSearchBar {
    margin-top: 10px; }
  .CreateNewStory .HubUserForm .TableContent,
  .UploadMediaFile .HubUserForm .TableContent {
    margin: 0;
    width: 100%; }

.tooltip.wrongDataType .tooltip-inner {
  background-color: red; }

.tooltip.wrongDataType .arrow::before {
  border-bottom-color: red; }

.MainContent [class*="EditGroup"] .needs-validation,
.MainContent .CreateGroup .needs-validation {
  margin-top: 0 !important; }

.MainContent [class*="EditGroup"].modal-dialog,
.MainContent .CreateGroup.modal-dialog {
  max-width: 700px; }

.MainContent [class*="EditGroup"] .statusText,
.MainContent .CreateGroup .statusText {
  margin-top: 15px; }

.MainContent [class*="EditGroup"] .btn.Ripple-parent,
.MainContent .CreateGroup .btn.Ripple-parent {
  float: right !important; }

.MainContent [class*="EditGroup"] .TableContent,
.MainContent .CreateGroup .TableContent {
  margin-top: 10px !important;
  width: 100%;
  margin-left: 0; }
  .MainContent [class*="EditGroup"] .TableContent thead tr td:nth-child(1),
  .MainContent .CreateGroup .TableContent thead tr td:nth-child(1) {
    width: 10%; }
  .MainContent [class*="EditGroup"] .TableContent thead tr td:nth-child(2),
  .MainContent .CreateGroup .TableContent thead tr td:nth-child(2) {
    width: 35%; }
  .MainContent [class*="EditGroup"] .TableContent thead tr td:nth-child(3),
  .MainContent .CreateGroup .TableContent thead tr td:nth-child(3) {
    width: 55%; }
  .MainContent [class*="EditGroup"] .TableContent .filterGroupChild .form-check-label,
  .MainContent .CreateGroup .TableContent .filterGroupChild .form-check-label {
    margin-left: 3px; }
  .MainContent [class*="EditGroup"] .TableContent tr:hover .form-check-input[type="checkbox"]:checked + label::before,
  .MainContent .CreateGroup .TableContent tr:hover .form-check-input[type="checkbox"]:checked + label::before {
    border-right: 2px solid white;
    border-bottom: 2px solid white; }

.custom-file-input {
  cursor: pointer; }
  .custom-file-input:focus {
    outline-color: #ffbb33; }
    .custom-file-input:focus ~ .custom-file-label {
      border-color: #ffbb33 !important;
      box-shadow: 0 0 0 0.2rem rgba(255, 187, 51, 0.6) !important; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    background-color: #383838 !important;
    color: white;
    top: -1px;
    right: -1px; }

.burli-user-form .md-form {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.EditStoryData .container,
.EditMediaData .container {
  padding-left: 0;
  padding-right: 0; }
  .EditStoryData .container .needs-validation,
  .EditMediaData .container .needs-validation {
    margin-top: 0 !important; }
    .EditStoryData .container .needs-validation .structureRow,
    .EditMediaData .container .needs-validation .structureRow {
      padding-top: 0; }
      .EditStoryData .container .needs-validation .structureRow .TableContent,
      .EditMediaData .container .needs-validation .structureRow .TableContent {
        margin-left: 0;
        margin-right: 0;
        width: 100%; }
        .EditStoryData .container .needs-validation .structureRow .TableContent .thickTop,
        .EditMediaData .container .needs-validation .structureRow .TableContent .thickTop {
          padding-top: 0; }

.EditStoryData .formTitle,
.EditMediaData .formTitle {
  font-size: 0.8rem; }

.EditStoryData .container,
.EditMediaData .container {
  max-width: 9999px; }

.dropdown .dropdown-menu {
  width: 100%;
  background-color: rgba(33, 33, 33, 0.9); }
  .dropdown .dropdown-menu a.dropdown-item {
    color: white; }
    .dropdown .dropdown-menu a.dropdown-item:hover, .dropdown .dropdown-menu a.dropdown-item:active {
      background-color: #ffbb33; }

@media only screen and (min-width: 501px) {
  .form-control {
    height: 30px;
    font-size: 14px !important; }
  .HubUserForm textarea.form-control {
    height: 100% !important; } }

@media only screen and (max-height: 850px) {
  .modal.fade .modal-dialog {
    height: 80% !important;
    border-radius: 2rem;
    overflow-x: visible; } }

.CreateNewStory .nav-tabs,
.UploadMediaFile .nav-tabs {
  margin-left: 0 !important;
  margin-bottom: 10px; }

.CreateNewStory .tab-content,
.UploadMediaFile .tab-content {
  padding: 0;
  padding-top: 0; }
  .CreateNewStory .tab-content .HubUserForm,
  .UploadMediaFile .tab-content .HubUserForm {
    margin-left: 0; }
  .CreateNewStory .tab-content .TableContent,
  .UploadMediaFile .tab-content .TableContent {
    margin-top: 0 !important; }

.CreateNewStory .col-12,
.UploadMediaFile .col-12 {
  padding-left: 0; }

.range-field {
  margin-top: 25px; }
  .range-field span {
    height: 30px !important;
    width: 30px !important;
    top: -25px !important;
    margin-left: -3.5% !important; }
    .range-field span .value {
      font-size: 15px !important;
      color: white !important;
      margin-left: -3px !important;
      margin-top: 3px !important; }
  .range-field input[type="range"] + .thumb {
    background-color: #474747; }
  .range-field input[type="range"]::-webkit-slider-thumb {
    background: #474747; }
    .range-field input[type="range"]::-webkit-slider-thumb:hover {
      background-color: #f69b1d; }
      .range-field input[type="range"]::-webkit-slider-thumb:hover + .thumb {
        background-color: #f69b1d; }

.rowAdjustSlider {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto; }
  .rowAdjustSlider .rc-slider-handle {
    background-color: #474747;
    border-color: #212121; }
    .rowAdjustSlider .rc-slider-handle:hover {
      background-color: #ffbb33;
      border-color: #f69b1d; }
    .rowAdjustSlider .rc-slider-handle:active {
      border-color: #ffbb33;
      box-shadow: 0 0 5px #ffbb33; }
    .rowAdjustSlider .rc-slider-handle.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      border-color: #ffbb33;
      box-shadow: 0 0 0 5px #ffbb33; }

.modal.fade .CreateNewStory.modal-dialog,
.modal.fade .UploadMediaFile.modal-dialog {
  height: auto;
  max-height: 90% !important;
  overflow: auto; }
  .modal.fade .CreateNewStory.modal-dialog.expanding,
  .modal.fade .UploadMediaFile.modal-dialog.expanding {
    -webkit-animation: expanding 1s forwards;
            animation: expanding 1s forwards; }
  .modal.fade .CreateNewStory.modal-dialog.collapsing,
  .modal.fade .UploadMediaFile.modal-dialog.collapsing {
    -webkit-animation: collapsing 1s forwards;
            animation: collapsing 1s forwards; }
  .modal.fade .CreateNewStory.modal-dialog.expanded,
  .modal.fade .UploadMediaFile.modal-dialog.expanded {
    max-width: 1500px; }
  .modal.fade .CreateNewStory.modal-dialog.collapsed,
  .modal.fade .UploadMediaFile.modal-dialog.collapsed {
    max-width: 500px; }

.modal {
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .modal ::-webkit-scrollbar {
    width: 7px; }
  .modal ::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .modal ::-webkit-scrollbar-thumb {
    background: #888888; }
  .modal ::-webkit-scrollbar-thumb:hover {
    background: #555555; }

@-webkit-keyframes expanding {
  0% {
    max-width: 500px; }
  100% {
    max-width: 1500px; } }

@keyframes expanding {
  0% {
    max-width: 500px; }
  100% {
    max-width: 1500px; } }

@-webkit-keyframes collapsing {
  0% {
    max-width: 1500px; }
  100% {
    max-width: 500px; } }

@keyframes collapsing {
  0% {
    max-width: 1500px; }
  100% {
    max-width: 500px; } }

.ExpandDiv #arrowAnim, .UnexpandDiv #arrowAnim {
  width: 30px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ExpandDiv #arrowAnim.leftArrow .arrow, .UnexpandDiv #arrowAnim.leftArrow .arrow {
    transform: rotate(-45deg); }
  .ExpandDiv #arrowAnim.rightArrow .arrow, .UnexpandDiv #arrowAnim.rightArrow .arrow {
    transform: rotate(135deg); }

.ExpandDiv .arrow, .UnexpandDiv .arrow {
  width: 10px;
  height: 10px;
  border: 5px solid;
  border-color: #f69b1d transparent transparent #f69b1d; }

.ExpandDiv .leftArrowSliding, .UnexpandDiv .leftArrowSliding {
  position: absolute;
  -webkit-animation: slideLeft 1s linear infinite;
  animation: slideLeft 1s linear infinite; }

.ExpandDiv .rightArrowSliding, .UnexpandDiv .rightArrowSliding {
  position: absolute;
  -webkit-animation: slideRight 1s linear infinite;
  animation: slideRight 1s linear infinite; }

.ExpandDiv .delay1, .UnexpandDiv .delay1 {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s; }

.ExpandDiv .delay2, .UnexpandDiv .delay2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

.ExpandDiv .delay3, .UnexpandDiv .delay3 {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s; }

.ExpandDiv .delay4, .UnexpandDiv .delay4 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

.ExpandDiv .delay5, .UnexpandDiv .delay5 {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s; }

.ExpandDiv .delay6, .UnexpandDiv .delay6 {
  -webkit-animation-delay: 0.88s;
  animation-delay: 0.88s; }

.ExpandDiv .delay7, .UnexpandDiv .delay7 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s; }

@-webkit-keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(3vw); }
  20% {
    opacity: 1;
    transform: translateX(2vw); }
  80% {
    opacity: 1;
    transform: translateX(-2vw); }
  100% {
    opacity: 0;
    transform: translateX(-3vw); } }

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(3vw); }
  20% {
    opacity: 1;
    transform: translateX(2vw); }
  80% {
    opacity: 1;
    transform: translateX(-2vw); }
  100% {
    opacity: 0;
    transform: translateX(-3vw); } }

@-webkit-keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-3vw); }
  20% {
    opacity: 1;
    transform: translateX(-2vw); }
  80% {
    opacity: 1;
    transform: translateX(2vw); }
  100% {
    opacity: 0;
    transform: translateX(3vw); } }

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-3vw); }
  20% {
    opacity: 1;
    transform: translateX(-2vw); }
  80% {
    opacity: 1;
    transform: translateX(2vw); }
  100% {
    opacity: 0;
    transform: translateX(3vw); } }

.CreateNewStory .modal-dialog,
.EditStoryData .modal-dialog,
.EditMediaData .modal-dialog,
.UploadMediaFile .modal-dialog {
  max-width: 700px; }
  .CreateNewStory .modal-dialog .modal-content .modal-body form.StoryDetailsForm, .CreateNewStory .modal-dialog .modal-content .modal-body form.ElementDetailsForm,
  .EditStoryData .modal-dialog .modal-content .modal-body form.StoryDetailsForm,
  .EditStoryData .modal-dialog .modal-content .modal-body form.ElementDetailsForm,
  .EditMediaData .modal-dialog .modal-content .modal-body form.StoryDetailsForm,
  .EditMediaData .modal-dialog .modal-content .modal-body form.ElementDetailsForm,
  .UploadMediaFile .modal-dialog .modal-content .modal-body form.StoryDetailsForm,
  .UploadMediaFile .modal-dialog .modal-content .modal-body form.ElementDetailsForm {
    width: 100%;
    max-width: inherit; }
  .CreateNewStory .modal-dialog .modal-content .modal-body .uploadMediaButton,
  .EditStoryData .modal-dialog .modal-content .modal-body .uploadMediaButton,
  .EditMediaData .modal-dialog .modal-content .modal-body .uploadMediaButton,
  .UploadMediaFile .modal-dialog .modal-content .modal-body .uploadMediaButton {
    z-index: 1;
    height: 37px;
    float: right !important;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    pointer-events: none;
    padding-left: 30px;
    padding-right: 30px; }
  .CreateNewStory .modal-dialog .modal-content .modal-body .custom-file-label.uploading,
  .EditStoryData .modal-dialog .modal-content .modal-body .custom-file-label.uploading,
  .EditMediaData .modal-dialog .modal-content .modal-body .custom-file-label.uploading,
  .UploadMediaFile .modal-dialog .modal-content .modal-body .custom-file-label.uploading {
    background-color: #d0d0d0; }
  .CreateNewStory .modal-dialog .modal-content .modal-body .custom-file,
  .EditStoryData .modal-dialog .modal-content .modal-body .custom-file,
  .EditMediaData .modal-dialog .modal-content .modal-body .custom-file,
  .UploadMediaFile .modal-dialog .modal-content .modal-body .custom-file {
    margin-top: 10px; }
  .CreateNewStory .modal-dialog .modal-content .modal-body .formTitle,
  .EditStoryData .modal-dialog .modal-content .modal-body .formTitle,
  .EditMediaData .modal-dialog .modal-content .modal-body .formTitle,
  .UploadMediaFile .modal-dialog .modal-content .modal-body .formTitle {
    font-size: 18px; }
  .CreateNewStory .modal-dialog .modal-content .modal-body .LargeCheckbox,
  .EditStoryData .modal-dialog .modal-content .modal-body .LargeCheckbox,
  .EditMediaData .modal-dialog .modal-content .modal-body .LargeCheckbox,
  .UploadMediaFile .modal-dialog .modal-content .modal-body .LargeCheckbox {
    margin-top: 5px; }
  .CreateNewStory .modal-dialog .modal-content .modal-body textarea#storyText,
  .EditStoryData .modal-dialog .modal-content .modal-body textarea#storyText,
  .EditMediaData .modal-dialog .modal-content .modal-body textarea#storyText,
  .UploadMediaFile .modal-dialog .modal-content .modal-body textarea#storyText {
    margin-top: 25px; }

.CreateUser .modal-dialog,
.UpdateUser .modal-dialog {
  max-width: 700px; }
  .CreateUser .modal-dialog .btn,
  .UpdateUser .modal-dialog .btn {
    display: block; }
  .CreateUser .modal-dialog .row,
  .UpdateUser .modal-dialog .row {
    margin-bottom: 10px; }

.CreateGroup .modal-dialog,
[class*="EditGroup"] .modal-dialog {
  max-width: 800px; }
  .CreateGroup .modal-dialog .TableContent,
  [class*="EditGroup"] .modal-dialog .TableContent {
    margin: auto; }
  .CreateGroup .modal-dialog .modal-content .btn.Ripple-parent,
  [class*="EditGroup"] .modal-dialog .modal-content .btn.Ripple-parent {
    float: right !important;
    margin-right: 35px !important; }
  .CreateGroup .modal-dialog .modal-content .ProductionCentre thead tr td:nth-child(1),
  [class*="EditGroup"] .modal-dialog .modal-content .ProductionCentre thead tr td:nth-child(1) {
    width: 5%; }
  .CreateGroup .modal-dialog .modal-content .ProductionCentre thead tr td:nth-child(2),
  [class*="EditGroup"] .modal-dialog .modal-content .ProductionCentre thead tr td:nth-child(2) {
    width: 30%; }
  .CreateGroup .modal-dialog .modal-content .ProductionCentre thead tr td:nth-child(3),
  [class*="EditGroup"] .modal-dialog .modal-content .ProductionCentre thead tr td:nth-child(3) {
    width: 65%; }

.Vocabulary thead tr, .Vocabulary tbody tr {
  width: 100% !important;
  display: table-row !important; }
  .Vocabulary thead tr th:nth-child(1), .Vocabulary thead tr td:nth-child(1), .Vocabulary tbody tr th:nth-child(1), .Vocabulary tbody tr td:nth-child(1) {
    width: 95% !important; }
  .Vocabulary thead tr th:nth-child(2), .Vocabulary thead tr td:nth-child(2), .Vocabulary tbody tr th:nth-child(2), .Vocabulary tbody tr td:nth-child(2) {
    width: 5% !important; }
  .Vocabulary thead tr th:nth-child(3), .Vocabulary thead tr td:nth-child(3), .Vocabulary tbody tr th:nth-child(3), .Vocabulary tbody tr td:nth-child(3) {
    width: 5% !important; }

.Vocabulary .parent-tbody {
  width: 100% !important;
  display: table-row-group !important; }

#manageVocabPopover {
  border-radius: 1.2rem;
  max-width: 800px;
  height: 600px;
  top: 200px !important;
  z-index: 0; }
  #manageVocabPopover #manageVocabPopoverTitle {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: #212121;
    color: #f69b1d;
    font-family: Roboto;
    font-weight: 900;
    font-size: 28px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px; }
  #manageVocabPopover #manageVocabPopoverContent {
    width: 680px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 510px;
    /* Handle */
    /* Handle on hover */ }
    #manageVocabPopover #manageVocabPopoverContent::-webkit-scrollbar-track {
      background: #f1f1f1; }
    #manageVocabPopover #manageVocabPopoverContent::-webkit-scrollbar-thumb {
      background: #888; }
    #manageVocabPopover #manageVocabPopoverContent::-webkit-scrollbar-thumb:hover {
      background: #555; }
    #manageVocabPopover #manageVocabPopoverContent::-webkit-scrollbar {
      width: 10px; }
    #manageVocabPopover #manageVocabPopoverContent .TableToolbarTop .col-4 {
      margin-top: 8px; }
  #manageVocabPopover .closeButton {
    position: absolute;
    right: 0px;
    top: -5px;
    padding: 1rem 1rem;
    cursor: pointer; }
    #manageVocabPopover .closeButton svg {
      font-weight: bolder;
      font-size: 20px;
      color: white; }
    #manageVocabPopover .closeButton:hover svg {
      color: #f69b1d; }
  #manageVocabPopover .structureRow {
    padding-top: 0; }
  #manageVocabPopover .TableContent {
    margin-top: 0 !important; }
    #manageVocabPopover .TableContent .filterSearchDiv {
      margin-top: 8px; }
      #manageVocabPopover .TableContent .filterSearchDiv svg {
        top: 20px; }
    #manageVocabPopover .TableContent .rowEditor .arrow::before, #manageVocabPopover .TableContent .rowEditor .arrow::after {
      top: 1px !important; }
  #manageVocabPopover .arrow::before, #manageVocabPopover .arrow::after {
    top: 85px; }
  #manageVocabPopover .onDelete .arrow::before, #manageVocabPopover .onDelete .arrow::after {
    top: 1px; }

@media only screen and (max-width: 1000px) {
  #manageVocabPopover {
    max-width: 80%; }
    #manageVocabPopover #manageVocabPopoverContent {
      width: 100%; } }

@media only screen and (max-height: 800px) {
  #manageVocabPopover {
    max-height: 100%;
    transform: none;
    top: 0 !important;
    overflow-y: auto; } }

.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent,
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent {
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent thead tr td:nth-child(1),
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent thead tr td:nth-child(1) {
    width: 90%; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent thead tr td:nth-child(2),
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent thead tr td:nth-child(2) {
    width: 5%; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent thead tr td:nth-child(3),
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent thead tr td:nth-child(3) {
    width: 5%; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent tbody,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent tbody {
    display: table;
    width: 105.3%; }
    .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent tbody tr td:nth-child(1),
    .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent tbody tr td:nth-child(1) {
      width: 90%; }
    .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent tbody tr td:nth-child(2),
    .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent tbody tr td:nth-child(2) {
      width: 5%; }
    .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent tbody tr td:nth-child(3),
    .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent tbody tr td:nth-child(3) {
      width: 5%; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent ::-webkit-scrollbar,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent ::-webkit-scrollbar {
    width: 7px; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent ::-webkit-scrollbar-track,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent ::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent ::-webkit-scrollbar-thumb,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent ::-webkit-scrollbar-thumb {
    background: #888888; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent ::-webkit-scrollbar-thumb:hover,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent ::-webkit-scrollbar-thumb:hover {
    background: #555555; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent .parent-tbody,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent .parent-tbody {
    max-height: 100px; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent .addRow-tbody tr,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent .addRow-tbody tr {
    width: 100%;
    display: inline-flex;
    padding-right: 0; }
    .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent .addRow-tbody tr td:nth-child(1),
    .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent .addRow-tbody tr td:nth-child(1) {
      display: inline-block; }
    .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent .addRow-tbody tr td:nth-child(2).InputAddRow,
    .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent .addRow-tbody tr td:nth-child(2).InputAddRow {
      width: 10%;
      padding-left: 10px; }

.vocabTooltip {
  padding-left: 40px !important; }

.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent thead tr td:nth-child(1),
.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent thead tr th:nth-child(1),
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent thead tr td:nth-child(1),
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent thead tr th:nth-child(1) {
  width: 95%; }

.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent .parent-tbody,
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent .parent-tbody {
  width: 100%; }

.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent td:nth-child(1),
.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent th:nth-child(1),
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent td:nth-child(1),
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent th:nth-child(1) {
  width: 95%; }

.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent td:nth-child(2),
.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent th:nth-child(2),
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent td:nth-child(2),
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent th:nth-child(2) {
  width: 5%;
  padding-left: 0;
  cursor: pointer; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent td:nth-child(2):hover svg,
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent th:nth-child(2):hover svg,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent td:nth-child(2):hover svg,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent th:nth-child(2):hover svg {
    color: #f69b1d; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent td:nth-child(2).disabled svg,
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent th:nth-child(2).disabled svg,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent td:nth-child(2).disabled svg,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent th:nth-child(2).disabled svg {
    opacity: 0.4; }

.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent td .form-control,
.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent th .form-control,
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent td .form-control,
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent th .form-control {
  font-size: 12px !important;
  height: 21px !important;
  padding: 0.25rem; }

.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent td:nth-child(1),
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent td:nth-child(1) {
  width: 92%; }

.MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent td:nth-child(2).onDelete,
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent td:nth-child(2).onDelete {
  width: 7.1%; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary .TableContent td:nth-child(2).onDelete svg,
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary .TableContent td:nth-child(2).onDelete svg {
    margin-left: 55%; }

.MainContent .CreateDataField .HubUserForm .Vocabulary table .data td,
.MainContent [class*="EditDataField"] .HubUserForm .Vocabulary table .data td {
  font-weight: 400; }
  .MainContent .CreateDataField .HubUserForm .Vocabulary table .data td:nth-child(1),
  .MainContent [class*="EditDataField"] .HubUserForm .Vocabulary table .data td:nth-child(1) {
    font-weight: 780; }

[class*="EditDataField"] .btn,
.CreateDataField .btn {
  text-transform: none; }

[class*="EditDataField"] .md-form,
.CreateDataField .md-form {
  margin-right: 25px; }

[class*="EditDataField"] .form-check,
.CreateDataField .form-check {
  margin-left: 15px;
  margin-top: 5px; }

[class*="EditDataField"] .HubUserForm .generatedTable .parent-tbody,
.CreateDataField .HubUserForm .generatedTable .parent-tbody {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100px; }

[class*="EditDataField"] .HubUserForm,
.CreateDataField .HubUserForm {
  max-width: 780px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px; }
  [class*="EditDataField"] .HubUserForm .descriptionCol .md-form,
  .CreateDataField .HubUserForm .descriptionCol .md-form {
    margin-top: 0;
    height: 20%; }
  [class*="EditDataField"] .HubUserForm .descriptionCol,
  .CreateDataField .HubUserForm .descriptionCol {
    padding-left: 0;
    padding-top: 10px;
    margin-bottom: -20px; }
  [class*="EditDataField"] .HubUserForm .SelectionStatus div,
  .CreateDataField .HubUserForm .SelectionStatus div {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px; }
    [class*="EditDataField"] .HubUserForm .SelectionStatus div.statusText,
    .CreateDataField .HubUserForm .SelectionStatus div.statusText {
      margin-top: 5px; }
    [class*="EditDataField"] .HubUserForm .SelectionStatus div .metadataFormButtonsContainer,
    .CreateDataField .HubUserForm .SelectionStatus div .metadataFormButtonsContainer {
      margin: auto;
      position: relative;
      display: table;
      width: auto;
      padding: 0;
      display: flex; }
      [class*="EditDataField"] .HubUserForm .SelectionStatus div .metadataFormButtonsContainer .btn,
      .CreateDataField .HubUserForm .SelectionStatus div .metadataFormButtonsContainer .btn {
        font-size: 15px;
        padding: 7px 14px 7px 14px;
        border-radius: 1rem;
        margin-top: 40px;
        display: inline-block; }
  [class*="EditDataField"] .HubUserForm .status div,
  .CreateDataField .HubUserForm .status div {
    margin: auto; }
    [class*="EditDataField"] .HubUserForm .status div .Success,
    [class*="EditDataField"] .HubUserForm .status div .UpdateError,
    .CreateDataField .HubUserForm .status div .Success,
    .CreateDataField .HubUserForm .status div .UpdateError {
      margin: 0; }
  [class*="EditDataField"] .HubUserForm .HubNameRow,
  [class*="EditDataField"] .HubUserForm .TitleRow div,
  .CreateDataField .HubUserForm .HubNameRow,
  .CreateDataField .HubUserForm .TitleRow div {
    padding-left: 0; }
  [class*="EditDataField"] .HubUserForm .TitleRow h5,
  .CreateDataField .HubUserForm .TitleRow h5 {
    padding-top: 20px;
    font-size: 14px;
    color: #757575; }
    [class*="EditDataField"] .HubUserForm .TitleRow h5.focused,
    .CreateDataField .HubUserForm .TitleRow h5.focused {
      color: #f69b1d; }
  [class*="EditDataField"] .HubUserForm .structureRow,
  .CreateDataField .HubUserForm .structureRow {
    padding-top: 0;
    margin-top: -5px; }
    [class*="EditDataField"] .HubUserForm .structureRow table.table td,
    .CreateDataField .HubUserForm .structureRow table.table td {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; }
  [class*="EditDataField"] .HubUserForm .parent-tbody,
  .CreateDataField .HubUserForm .parent-tbody {
    display: block;
    overflow-y: auto;
    max-height: 200px;
    width: 1006%; }
    [class*="EditDataField"] .HubUserForm .parent-tbody tr,
    .CreateDataField .HubUserForm .parent-tbody tr {
      display: block; }
    [class*="EditDataField"] .HubUserForm .parent-tbody td:nth-child(1),
    .CreateDataField .HubUserForm .parent-tbody td:nth-child(1) {
      width: 1%; }
    [class*="EditDataField"] .HubUserForm .parent-tbody td:nth-child(2),
    .CreateDataField .HubUserForm .parent-tbody td:nth-child(2) {
      padding-left: 3%;
      width: 37%; }
    [class*="EditDataField"] .HubUserForm .parent-tbody td:nth-child(3),
    .CreateDataField .HubUserForm .parent-tbody td:nth-child(3) {
      padding-left: 2%; }
  [class*="EditDataField"] .HubUserForm .filterSearchDiv,
  .CreateDataField .HubUserForm .filterSearchDiv {
    margin-top: 10px; }
    [class*="EditDataField"] .HubUserForm .filterSearchDiv svg,
    .CreateDataField .HubUserForm .filterSearchDiv svg {
      margin-top: 5px; }

[class*="EditDataField"] .modal-content .modal-header .modal-title,
.CreateDataField .modal-content .modal-header .modal-title {
  font-size: 28px; }

[class*="EditDataField"] .md-form input[type="text"]:focus:not([readonly]),
.CreateDataField .md-form input[type="text"]:focus:not([readonly]) {
  box-shadow: 0 1px 0 0 #ffbb33;
  border-bottom: 1px solid #ffbb33; }
  [class*="EditDataField"] .md-form input[type="text"]:focus:not([readonly]) + label,
  .CreateDataField .md-form input[type="text"]:focus:not([readonly]) + label {
    color: #f69b1d; }

[class*="EditDataField"] label,
.CreateDataField label {
  margin-left: -5px; }

[class*="EditDataField"] .modal-dialog,
.CreateDataField .modal-dialog {
  max-width: 600px; }
  [class*="EditDataField"] .modal-dialog .modal-content .modal-body,
  .CreateDataField .modal-dialog .modal-content .modal-body {
    padding: 0 !important;
    overflow-x: inherit; }

[class*="EditDataField"] input.form-control,
[class*="EditDataField"] .form-group,
.CreateDataField input.form-control,
.CreateDataField .form-group {
  margin-bottom: 0; }

.editRow button {
  display: inline-block;
  float: right;
  margin-top: 11px !important; }

.MainContent {
  padding-left: 18%;
  width: 100%;
  padding-top: 46px; }

/* Responsive media queries: */
@media only screen and (max-width: 1024px) {
  .MainContent {
    padding-left: 0; } }

/* For Static width navbar later:*/
@media only screen and (min-width: 1295px) {
  .MainContent {
    padding-left: 230px !important; } }

/* Radio button customization */
.form-check-input[type="radio"]:not(:checked) + label:before,
.form-check-input[type="radio"]:checked + label:before {
  width: 24px;
  height: 24px;
  border: 2px solid #5a5a5a; }

.form-check-input[type="radio"] + label:after {
  margin-left: 8px;
  margin-top: 8px; }

.form-check-input[type="radio"]:checked + label:after {
  background-color: #f69b1d;
  border: 2px solid #f69b1d; }

.form-control:focus {
  border-color: #f69b1d !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 196, 2, 0.87) !important; }

table.table-hover tbody tr:hover {
  background-color: rgba(255, 196, 0, 0.3); }

.md-form textarea.md-textarea:focus:not([readonly]) {
  box-shadow: 0 1px 0 0 #ffbb33;
  border-bottom: 1px solid #ffbb33; }

.md-form input[type="text"]:focus:not([readonly]) + label,
.md-form input[type="password"]:focus:not([readonly]) + label,
.md-form input[type="email"]:focus:not([readonly]) + label,
.md-form input[type="url"]:focus:not([readonly]) + label,
.md-form input[type="time"]:focus:not([readonly]) + label,
.md-form input[type="date"]:focus:not([readonly]) + label,
.md-form input[type="datetime-local"]:focus:not([readonly]) + label,
.md-form input[type="tel"]:focus:not([readonly]) + label,
.md-form input[type="number"]:focus:not([readonly]) + label,
.md-form input[type="search-md"]:focus:not([readonly]) + label,
.md-form input[type="search"]:focus:not([readonly]) + label,
.md-form textarea.md-textarea:focus:not([readonly]) + label {
  color: #f69b1d; }

.md-form textarea ~ label.active {
  color: #757575; }

.disabledWithTooltip.disabled {
  pointer-events: auto !important;
  opacity: 0.4;
  cursor: default !important; }
  .disabledWithTooltip.disabled:hover svg {
    opacity: 0.4; }
  .disabledWithTooltip.disabled:hover {
    opacity: 0.4; }

div.disabled {
  cursor: none; }
  div.disabled svg {
    cursor: none;
    opacity: 0.4; }

svg.disabled {
  opacity: 0.5;
  cursor: auto; }
  svg.disabled:hover {
    color: #212121; }

.modal-content .modal-body .btn.disabledWithTooltip.disabled:hover {
  background-color: #474747 !important;
  opacity: 0.4; }

.disabledWithTooltip {
  cursor: auto !important; }

.form-control:disabled::-webkit-input-placeholder {
  font-style: italic; }

.form-control:disabled:-ms-input-placeholder {
  font-style: italic; }

.form-control:disabled::-ms-input-placeholder {
  font-style: italic; }

.form-control:disabled::placeholder {
  font-style: italic; }

.LargeCheckbox .form-check-input[type="checkbox"] + label:before {
  width: 30px;
  height: 30px; }

.LargeCheckbox .form-check-input[type="checkbox"]:checked + label:before {
  width: 15px;
  height: 2rem; }

.cross .form-check-input[type="checkbox"] + label:before {
  transform: translate(-50%, 0); }

.cross .form-check-input[type="checkbox"]:checked + label:before {
  top: -6px;
  content: "X";
  color: #ffbb33;
  font-size: 22px;
  transform: rotate(0) translate(50%, 0);
  border-right: 2px solid rgba(255, 187, 51, 0);
  border-bottom: 2px solid rgba(255, 187, 51, 0); }

.MainContent .TableToolbar .checkFilter:hover .cross .form-check-input[type="checkbox"]:checked + label::before {
  border-right: 2px solid rgba(255, 187, 51, 0);
  border-bottom: 2px solid rgba(255, 187, 51, 0);
  color: white; }

.rowActionTooltip {
  pointer-events: none;
  white-space: pre-wrap; }

.addRowEditor .arrow {
  margin-left: 30px; }

.disableClick {
  pointer-events: none; }

/*===================BURLI RADIO BUTTON STYLE=============================*/
/* Customize the label (the container) */
.radioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default radio button */
.radioContainer {
  font-size: 15px;
  /* On mouse-over, add a grey background color */ }
  .radioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .radioContainer .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    /* Create the indicator (the dot/circle - hidden when not checked) */ }
    .radioContainer .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      /* Style the indicator (dot/circle) */
      top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white; }
  .radioContainer:hover input ~ .checkmark {
    background-color: #ccc; }
  .radioContainer input:checked ~ .checkmark {
    background-color: #f69b1d;
    /* Show the indicator (dot/circle) when checked */ }
    .radioContainer input:checked ~ .checkmark:after {
      display: block; }

/*==================================================================================*/
/* The style below works with material UI - TextField: */
.MuiFormControl-root {
  width: 100%; }

.MuiTextField-root.MuiFormControl-root input {
  color: black !important; }

.MuiTextField-root.MuiFormControl-root .Mui-focused, .MuiTextField-root.MuiFormControl-root:focus, .MuiTextField-root.MuiFormControl-root div:focus {
  color: #ffbb33; }

.MuiTextField-root.MuiFormControl-root .MuiInput-underline:after {
  border-color: #ffbb33; }

.MuiTextField-root.MuiFormControl-root .MuiFormHelperText-root.Mui-error, .MuiTextField-root.MuiFormControl-root .MuiFormLabel-root.Mui-error {
  color: red; }

.MuiTextField-root.MuiFormControl-root .MuiFormLabel-root {
  top: -3px;
  font-size: 1.2rem; }
  .MuiTextField-root.MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-shrink {
    top: 1px; }

.Footer {
  position: fixed;
  bottom: 0px;
  height: 50px;
  z-index: 9999;
  width: 82%; }
  .Footer .fa {
    font-size: 15px;
    margin-right: 20px;
    font-weight: 400; }
  .Footer .navbar-brand {
    position: fixed;
    left: 55%; }
  .Footer p {
    text-align: center;
    width: 82%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px; }

@media only screen and (max-width: 600px) {
  .Footer .fa {
    margin-right: 0px !important; } }

@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .Footer p {
    width: 100%; } }

@media only screen and (min-width: 501px) {
  .Footer {
    width: 100%; }
    .Footer .navbar {
      height: 40px;
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0; }
      .Footer .navbar .fa {
        font-size: 20px; } }

/*Smaller footer*/
@media only screen and (max-width: 1000px) {
  .Footer {
    width: 100%; }
    .Footer .navbar p {
      font-size: 9px; } }

.ScreenSizeWarning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background: #4b4b4b;
  background: linear-gradient(180deg, #4b4b4b 0%, #363328 35%, #00252d 100%);
  overflow: hidden; }
  .ScreenSizeWarning .container {
    position: fixed;
    top: 15%;
    left: 15%;
    width: 65%; }
    .ScreenSizeWarning .container img {
      width: 100%;
      -webkit-filter: brightness(50%) sepia(100%) saturate(350%);
              filter: brightness(50%) sepia(100%) saturate(350%); }
    .ScreenSizeWarning .container h1, .ScreenSizeWarning .container p {
      color: #ffbb33;
      text-align: center; }
    .ScreenSizeWarning .container h1 {
      font-weight: 500;
      font-size: 30px; }

/* App.css is for generic page styling: */

/* Font: */
@font-face {
  font-family: "Anton";
  src: local("Anton"), url("/fonts/Anton-Regular.ttf") format("truetype");
}

/* Roboto */
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Assistant */
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* RobotoMono */
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-SemiBold.ttf") format("truetype");
  font-weight: 60;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/*About:*/

html,
body,
.App,
#root,
.MainContent {
  height: 100%;
}

/*bye x-scrollbar*/
body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.structureRow div {
  padding-left: 0 !important;
}

table .data td,
table .collapsibleRow td,
.structureRow .TableToolbar {
  font-family: Assistant;
  font-weight: 600;
  color: #474747;
}

body,
div {
  font-family: Assistant;
}

.collapsibleRow td,
.structureRow,
.pagesNavigation {
  font-weight: 400;
}

@media only screen and (min-width: 1295px) {
  .BurliNewsHub .structureRow div {
    font-size: 15px;
  }
}

.MainContent .table td,
.MainContent .table th {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.MainContent .table thead td,
.MainContent .table thead th {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* For Static width navbar later:*/
@media only screen and (min-width: 1295px) {
  .TopNav,
  .Footer {
    margin-left: 230px !important;
  }

  /* (60 * (((1530 - 1295) - (var(--variable-width) - 1295)) / (1530 - 1295)))); */
}

.TopNav,
.Footer {
  margin-left: 18%; /* Space from side nav */
}

/* Responsive media queries: */
@media only screen and (max-width: 501px) {
  /* For phones: */

  .TopNav,
  .Footer .navbar,
  .Footer {
    margin: 0 !important;
    width: 100% !important;
  }
}

/* Responsive media queries: */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  /* For IPad: */

  .TopNav,
  .Footer .navbar,
  .Footer,
  .MainContent {
    width: 100% !important;
    margin-left: 0 !important;
    padding-left: 0;
  }

  .MainContent {
    padding-top: 69px;
  }
}

/* Responsive media queries: */
@media only screen and (min-width: 501px) {
  /* For computer and ipad: */
  .TopNav .navbar {
    height: 70px;
  }
}

/* //Phone: */
@media only screen and (max-width: 1000px) {
  .structureRow div {
    font-size: 11px;
  }
}

/* Story and media: */
.structureRow {
  padding-top: 8px;
  padding-bottom: 8px;
}

.structureRow.slim {
  padding-top: 0;
  padding-bottom: 0;
}
.structureRow.slim.thickBottom {
  padding-bottom: 10px;
}

.structureRow.thickTop {
  padding-top: 12px;
}
.structureRow.slim.top {
  padding-top: 8px;
  padding-bottom: 0;
}

.row div {
  /* padding-left:0px !important; */
  padding-right: 0px !important;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

/*animations:*/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.TopNav,
.Footer {
  z-index: 150 !important;
}

.successMsg {
  color: #00be00;
}
.errorMsg {
  color: red;
}
.warningMsg {
  color: #f69b1d;
}

.fa-download {
  color: #474747;
}

/* removes input:-internal-autofill-selected on text cache dropdown: */
input {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255, 1);
}
body {
    margin: 0;
    padding: 0;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Segoe UI", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.main-content {
    padding:1.2em;
}


