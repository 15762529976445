body {
    margin: 0;
    padding: 0;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Segoe UI", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.main-content {
    padding:1.2em;
}

