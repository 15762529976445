/*SideNav Footer: */

$yellow-theme: #ffbb33;
$orange-theme: #f69b1d;

.NavBarFooter {
    position: absolute;
    bottom: 13%;
    width: 95%;
    left: 5%;

    .col-6 {
        padding-left: 0;
        margin-right: 5px;
        margin-top: 15px;
    }

    p,
    h5 {
        color: $orange-theme;
        font-size: 0.82rem;
        line-height: 16px;
        position: absolute;
        bottom: 25%;
        font-weight: 600;

        .hubSpecs {
            color: white;
            line-height: 14px;
            font-size: 0.7rem;
            font-weight: 300;
        }
    }

    .col-5,
    .col-7 {
        padding: 2px !important;
    }

    .AboutButton {
        color: white;

        svg {
            margin-right: 3px;
        }

        &:hover {
            color: $orange-theme;
        }
    }
}

.imgCard {
    display: block;
    margin: auto;
    width: 90%;
    margin-bottom: 40px;
    margin-top: 40px;
}

.SideNavbar {
    background-color: #1c1e20 !important;
    height: 120vh;
    position: fixed;
    width: 18%;
    left: 0;
    top: -30px;
    z-index: 300;
    max-width: 235px;

    div, a {
        font-family: Roboto;
    }

    .sideNavMain {
        height: 100%;
        padding: 0 !important;
        background-color: rgba(255, 0, 0, 0);
        padding: 0;

        .flex-column {
            position: absolute;
            top: 30px;
            width: 100%;
            .nav-link {
                padding-top: 8px;
                padding-bottom: 8px;
            }
            .sidebarWrapper {
                /* To prevent animation glitching */
                padding-top: 5px;
                padding-bottom: 5px;
                cursor: pointer;
                a {
                    display: block;
                    margin-left: 0;
                    padding-left: 25px;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }

                &:hover,
                &:hover .subNav svg {
                    background-color: #fec122 !important;
                    a {
                        color: black !important;
                    }
                }
                /*Subnav multiplelines:*/
                .multilineNav {
                    width: 50px;
                    display: inline-block;
                    svg {
                        margin-bottom: 29%;
                    }
                }
                .multilineNavText {
                    width: 65%;
                    display: inline-block;
                }
                .subNav {
                    margin-left: 12px;
                    padding: 10px;
                    &:hover a {
                        color: #ffffff;
                    }
                }
            }
            .sidebarWrapperActive {
                &,
                .subNav svg {
                    background-color: #f69b1d;
                }
                a {
                    color: black !important;
                }
            }
            .sidebarWrapperParentActive {
                background-color: #f69b1d;
                color: black !important;
            }

            .nav-link:hover {
                background-color: #fec122 !important;
            }

            a {
                color: #fec122 !important;
                /*prevent them from aligning to middle altogether*/
                text-align: left;
                &:hover {
                    color: #1c1e20 !important;
                    text-decoration: none !important;
                }
            }
        }

        a svg {
            margin-left: 20px;
        }
    }
}

.sideNavActive {
    background-color: #ff8c00;
    a {
        color: #1c1e20 !important;
    }
}

.sideNavInactive {
    background-color: rgba(255, 255, 255, 0);
}

.mobileNavActivator {
    visibility: collapse;
}

/* Responsive media queries: */
@media only screen and (max-width: 501px) {
    .SideNavbar .sideNavMain {
        width: 320%;
        .flex-column {
            .nav-link {
                padding-top: 10;
                padding-bottom: 10;
            }
            .sidebarWrapper {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        a {
            font-size: 18px !important;
        }
        .imgCard {
            margin-top: 20px;
            margin-bottom: 5px;
        }
    }

    .NavBarFooter {
        width: 300%;
    }

    .imgCard {
        height: 80px;
    }

    .slideHorizontal {
        left: -220px !important;
        transform: translateX(0px) !important;
        transition: transform 200ms ease-in !important;
        &.slideIn {
            transform: translateX(220px) !important;
        }

        &.slideOut {
            transform: translateX(0px) !important;
        }
    }
}

/* Responsive media queries: */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
    .SideNavbar {
        width: 30% !important;
        left: -310px !important;
        transform: translateX(0px) !important;
        transition: transform 200ms ease-in !important;

        &.slideIn {
            transform: translateX(310px) !important;
        }

        &.slideOut {
            transform: translateX(0px) !important;
        }
    }
}

/* Responsive media queries: */
@media only screen and (max-width: 1024px) {
    .mobileNavActivator {
        position: fixed;
        z-index: 400;
        top: 12px;
        left: 12px;
        font-size: 30px;
        color: white;
        visibility: visible !important;
    }
}

/* Phones: */
@media only screen and (max-height: 550px) {
    .navbar {
        display:block;
    }

    .SideNavbar {
        overflow: auto;
        .sideNavMain {
            height: auto;
            margin-bottom: 120px;
            .flex-column {
                position: relative;
                display: block;
                margin-top: 100px;
                top: 0;
            }
        }
    }
    .NavBarFooter {
        position: relative;
        display: block;
        margin-top: 0;
    }
}
