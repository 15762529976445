.CreateGroup,
[class*="EditGroup"] {
    .modal-dialog {
        max-width: 800px;
        .TableContent {
            margin: auto;
        }
        .modal-content {
            .btn.Ripple-parent {
                float: right !important;
                margin-right: 35px !important;
            }
            .ProductionCentre thead tr td {
                &:nth-child(1) {
                    width: 5%;
                }
                &:nth-child(2) {
                    width: 30%;
                }
                &:nth-child(3) {
                    width: 65%;
                }
            }
        }
    }
}
