/*Modal styling: */

$yellow-theme: #ffbb33;
$orange-theme: #f69b1d;
$dark-theme: #212121;
$semidark-theme: #474747;

.modal {
    pointer-events: none; //to fix the modal-easily-click-outside-then-quit bug
    .modal-dialog {
        pointer-events: all;
    }
}

.modal-content {
    border-radius: 1.4rem !important;
    .modal-header {
        border-top-left-radius: 1.25rem !important;
        border-top-right-radius: 1.25rem !important;
        .modal-title {
            color: #f69b1d;
            font-family: Roboto;
            font-weight: 900;
            font-size: 38px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
        }
        /*Modal close button: */
        .close {
            color: white;
            opacity: 1;
            &:hover {
                color: #f69b1d !important;
                opacity: 1 !important;
                text-shadow: none !important;
            }
        }
    }
    .modal-body {
        .formDropdown .btn {
            text-transform: none;
        }
        .btn {
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: 8px;
            margin-bottom: 10px;
            background-color: #383838 !important;
            color: white !important;
            font-size: 15px;
            &:hover {
                color: #ffffff;
                background-color: #f69b1d !important;
            }
            &.Ripple-parent {
                float: none !important;
                margin-right: auto !important;
                margin-left: auto !important;
            }
            &.wrongDataType {
                background-color: red !important;
                &:hover {
                    background-color: red !important;
                }
            }
        }
        .uploadedFileDisplay {
            margin-top: 15px;
        }
        /* for upload: */
        .input-group:hover .custom-file-label {
            box-shadow: 0 0 15px #ffbb33;
        }
    }
}

.additionalSettingsTooltip {
    pointer-events: none !important;
    top: -50px !important;
}

.CreateNewStory,
.UploadMediaFile {
    //max-width: 500px;

    .collapsableDiv {
        position: absolute;
        min-width: calc(100% - 474px);
        display: inline-block;
        height: fit-content;
        float: left;
        overflow: hidden;
        height: 700px;
        margin-left: 20px;
    }

    .StoryDetailsForm,
    .ElementDetailsForm {
        .storyKeyword, .elementKeyword {
            margin-bottom: 20px;
            margin-top: -5px;
        }

        .multiValsSelectorWrapper {
            margin-top: 0;
            margin-bottom: 0;
        }

        .disablePopup {
            margin-top: 0;
            display: inline-block;
            .formDropdown {
                margin-top: 0;
                display: inline-block;
                .dropdown-menu {
                    margin-top: 0;
                }
            }
        }

        div,
        textarea,
        input {
            margin-top: 10px;
            margin-bottom: 10px;
            .filterCountDisplay {
                margin-top: 0;
                .filterCount {
                    display: block;
                }
            }
            .filterDropdownToggle {
                height: 40px;
            }
            .MuiInputBase-input {
                //margin-top: 0;
                margin-bottom: 0;
            }
            .filterDropdown {
                div {
                    margin-bottom: 0;
                }
            }
        }
        .filterDropdown {
            margin-top: 0;
            .filterDropdownMenu {
                margin-top: 0;
                div,
                input {
                    margin-top: 0;
                }
                .card-body {
                    padding-left: 0;
                    padding-right: 0;
                }
                .col-12 {
                    padding-left: 0;
                }
                .form-check {
                    display: block;
                    padding-left: 0;
                    width: 100%;
                    .form-check-label {
                        padding-left: 35px;
                        &::before {
                            top: 0;
                        }
                    }
                }
            }
        }
        textarea {
            min-height: 320px;
        }
        .ImportantInfoRow {
            margin-top: -15px;
        }
        .LargeCheckbox {
            margin-top: -2px;
            .formTitle {
                text-align: center;
                margin-bottom: 10px;
            }
            .filterGroupChild {
                margin-top: 0;
            }
            .form-check .form-check-label {
                margin-top: -15px;
            }
            .checkboxContainer {
                padding-left: 0;
                .checkmark {
                    margin: auto;
                    position: relative;
                    display: block;
                }
            }
        }
        .custom-file {
            margin-top: 0;
        }
        .col-10 {
            padding-left: 0;
        }
        .col-2 {
            padding-left: 5px;
        }
        .rowEditor {
            margin-left: 280px;
            margin-top: 0px;
            .arrow {
                left: 9px;
                top: -28px;
            }
            .rowEditorContent {
                margin-top: 0;
            }
            .dropdown-menu {
                margin-top: 0;
                padding: 0;
                width: 95%;
            }
        }
        .categoryBadge {
            padding: 5px;
            background-color: #e6e6e6;
            display: inline-block;
            border-radius: 2rem;
            padding-left: 25px;
            padding-right: 25px;
            margin-top: 5px;
            margin-right: 10px;
            .deleteIcon {
                margin-left: 5px;
                cursor: pointer;
                :hover {
                    color: $orange-theme;
                }
            }
        }
        svg {
            display: inline-block;
            cursor: pointer;
            &:hover {
                color: $orange-theme;
            }
        }
    }

    .modal-content .modal-body {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;

        .burli-metadata-form {
            margin-top: 0 !important;
            .structureRow {
                padding-top: 5px;
            }
        }

        .ExpandDiv,
        .UnexpandDiv {
            position: absolute;
            height: 90%;
            width: 65px;
            border-radius: 2rem;
            top: 0%;
            cursor: pointer;
            #arrowAnim {
                position: absolute;
                top: 45%;
                margin-left: 5%;
                margin: auto;
                & .arrow {
                    border-color: #757575 transparent transparent #757575;
                }
            }
            &:hover {
                //background-color: #ffbb33ba;
                background: linear-gradient(
                    90deg,
                    rgba(255, 187, 51, 0) 0%,
                    rgba(255, 187, 51, 0.4) 30%,
                    rgba(255, 187, 51, 0.8) 50%,
                    rgba(255, 187, 51, 0.4) 70%,
                    rgba(255, 187, 51, 0) 100%
                );
                #arrowAnim .arrow {
                    border-color: $orange-theme transparent transparent $orange-theme;
                    //background-color: $orange-theme;
                }
            }
            &.disabledWithTooltip {
                &:hover {
                    background: linear-gradient(
                        90deg,
                        rgba(33, 33, 33, 0) 0%,
                        rgba(33, 33, 33, 0.4) 30%,
                        rgba(33, 33, 33, 0.4) 70%,
                        rgba(33, 33, 33, 0) 100%
                    );
                    #arrowAnim {
                        position: absolute;
                        top: 45%;
                        margin-left: 5%;
                        margin: auto;
                        & .arrow {
                            border-color: #757575 transparent transparent #757575;
                        }
                    }
                }
            }
        }

        .ExpandDiv {
            background: linear-gradient(
                90deg,
                rgba(33, 33, 33, 0) 0%,
                rgba(33, 33, 33, 0.4) 30%,
                rgba(33, 33, 33, 0.4) 70%,
                rgba(33, 33, 33, 0) 100%
            );
        }

        .md-form {
            margin: 0;
        }
        form {
            &.StoryDetailsForm,
            &.ElementDetailsForm {
                float: left;
                width: 430px;
                max-width: 430px;
            }
            /*div,
            textarea,
            input {
                margin-bottom: 1rem;
                margin-top: 0.5rem;
            }
            &.burli-metadata-form {
                div,
                textarea,
                input {
                    margin-bottom: 0;
                    margin-top: 0;
                }
                .thickTop {
                    padding: 0;
                }
            }*/
        }

        .HorizontalMetadataCol {
            display: inline-block;
            float: left;
            position: relative;
            margin-left: 30px;
            /* width: calc(100% - 500px); set a static length first by calculating the width first using JS, after it's expanded set to calc (100% - 500px) */
            top: 0;
            position: absolute;
        }
    }
    .HubUserForm {
        margin-left: 15px;
        padding: 0;
        .TableToolbarTop .col-4,
        .filterSearchBar {
            margin-top: 10px;
        }
        .TableContent {
            margin: 0;
            width: 100%;
        }
    }
}

.tooltip {
    //margin-left: -30px !important;
    &.wrongDataType {
        .tooltip-inner {
            background-color: red;
        }
        .arrow::before {
            border-bottom-color: red;
        }
    }
}
.MainContent {
    [class*="EditGroup"],
    .CreateGroup {
        .needs-validation {
            margin-top: 0 !important;
        }
        &.modal-dialog {
            max-width: 700px;
        }
        .statusText {
            margin-top: 15px;
        }
        .btn.Ripple-parent {
            float: right !important;
        }
        .TableContent {
            margin-top: 10px !important;
            width: 100%;
            margin-left: 0;
            thead {
                tr {
                    td {
                        &:nth-child(1) {
                            width: 10%;
                        }
                        &:nth-child(2) {
                            width: 35%;
                        }
                        &:nth-child(3) {
                            width: 55%;
                        }
                    }
                }
            }
            .filterGroupChild .form-check-label {
                margin-left: 3px;
            }
            tr:hover {
                .form-check-input[type="checkbox"]:checked + label::before {
                    //tick:
                    border-right: 2px solid white;
                    border-bottom: 2px solid white;
                }
            }
        }
    }
}

.custom-file-input {
    cursor: pointer;
    &:focus {
        outline-color: #ffbb33;
        & ~ .custom-file-label {
            border-color: #ffbb33 !important;
            box-shadow: 0 0 0 0.2rem rgba(255, 187, 51, 0.6) !important;
        }
    }

    &:lang(en) ~ .custom-file-label::after {
        background-color: #383838 !important;
        color: white;
        top: -1px;
        right: -1px;
    }
}

.burli-user-form .md-form {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.EditStoryData,
.EditMediaData {
    .container {
        padding-left: 0;
        padding-right: 0;
        .needs-validation {
            margin-top: 0 !important;
            .structureRow {
                padding-top: 0;
                .TableContent {
                    margin-left: 0;
                    margin-right: 0;
                    width: 100%;
                    .thickTop {
                        padding-top: 0;
                    }
                }
            }
        }
    }
    // &.width1 {
    //     max-width: 400px;
    // }
    // &.width2 {
    //     max-width: 700px;
    // }
    // &.width3 {
    //     max-width: 1100px;
    // }
    // &.width4 {
    //     max-width: 1400px;
    // }
    .formTitle {
        font-size: 0.8rem;
    }
    .container {
        max-width: 9999px;
    }
}

.dropdown {
    .dropdown-menu {
        width: 100%;
        background-color: rgba(33, 33, 33, 0.9);
        a.dropdown-item {
            color: white;
            &:hover,
            &:active {
                background-color: $yellow-theme;
            }
        }
    }
}

@media only screen and (min-width: 501px) {
    .form-control {
        height: 30px;
        font-size: 14px !important;
    }
    .HubUserForm textarea.form-control {
        height: 100% !important;
    }
}

//Phone
@media only screen and (max-height: 850px) {
    .modal.fade .modal-dialog {
        height: 80% !important;
        border-radius: 2rem;
        overflow-x: visible;
    }
}
//@media only screen and (max-width: 1000px) {
.CreateNewStory,
.UploadMediaFile {
    .nav-tabs {
        margin-left: 0 !important;
        margin-bottom: 10px;
    }
    .tab-content {
        padding: 0;
        padding-top: 0;
        .HubUserForm {
            margin-left: 0;
        }
        .TableContent {
            margin-top: 0 !important;
        }
    }
    .col-12 {
        padding-left: 0;
    }
}
//}

.range-field {
    margin-top: 25px;
    span {
        height: 30px !important;
        width: 30px !important;
        top: -25px !important;
        margin-left: -3.5% !important;
        .value {
            font-size: 15px !important;
            color: white !important;
            margin-left: -3px !important;
            margin-top: 3px !important;
        }
    }
    input[type="range"] {
        & + .thumb {
            background-color: $semidark-theme;
        }
        &::-webkit-slider-thumb {
            background: $semidark-theme;
            &:hover {
                background-color: $orange-theme;
                & + .thumb {
                    background-color: $orange-theme;
                }
            }
        }
    }
}

.rowAdjustSlider {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    .rc-slider-handle {
        background-color: $semidark-theme;
        border-color: $dark-theme;
        &:hover {
            background-color: $yellow-theme;
            border-color: $orange-theme;
        }
        &:active {
            border-color: $yellow-theme;
            box-shadow: 0 0 5px $yellow-theme;
        }
        &.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
            border-color: $yellow-theme;
            box-shadow: 0 0 0 5px $yellow-theme;
        }
    }
}

//Widget Anims:
.modal.fade .CreateNewStory.modal-dialog,
.modal.fade .UploadMediaFile.modal-dialog {
    &.expanding {
        animation: expanding 1s forwards;
    }
    &.collapsing {
        animation: collapsing 1s forwards;
    }
    &.expanded {
        max-width: 1500px;
    }
    &.collapsed {
        max-width: 500px;
    }
    height: auto; //fixing the modal anim-breaking bug
    max-height: 90% !important;
    overflow: auto;
}

.modal {
    ::-webkit-scrollbar {
        width: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555555;
    }
}

@keyframes expanding {
    0% {
        max-width: 500px;
    }
    100% {
        max-width: 1500px;
    }
}

@keyframes collapsing {
    0% {
        max-width: 1500px;
    }
    100% {
        max-width: 500px;
    }
}
