/* App.css is for generic page styling: */

/* Font: */
@font-face {
  font-family: "Anton";
  src: local("Anton"), url("/fonts/Anton-Regular.ttf") format("truetype");
}

/* Roboto */
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Assistant */
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Assistant";
  src: local("Assistant"), url("/fonts/Assistant/Assistant-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* RobotoMono */
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-SemiBold.ttf") format("truetype");
  font-weight: 60;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"), url("/fonts/RobotoMono/RobotoMono-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/*About:*/

html,
body,
.App,
#root,
.MainContent {
  height: 100%;
}

/*bye x-scrollbar*/
body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.structureRow div {
  padding-left: 0 !important;
}

table .data td,
table .collapsibleRow td,
.structureRow .TableToolbar {
  font-family: Assistant;
  font-weight: 600;
  color: #474747;
}

body,
div {
  font-family: Assistant;
}

.collapsibleRow td,
.structureRow,
.pagesNavigation {
  font-weight: 400;
}

@media only screen and (min-width: 1295px) {
  .BurliNewsHub .structureRow div {
    font-size: 15px;
  }
}

.MainContent .table td,
.MainContent .table th {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.MainContent .table thead td,
.MainContent .table thead th {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* For Static width navbar later:*/
@media only screen and (min-width: 1295px) {
  .TopNav,
  .Footer {
    margin-left: 230px !important;
  }

  /* (60 * (((1530 - 1295) - (var(--variable-width) - 1295)) / (1530 - 1295)))); */
}

.TopNav,
.Footer {
  margin-left: 18%; /* Space from side nav */
}

/* Responsive media queries: */
@media only screen and (max-width: 501px) {
  /* For phones: */

  .TopNav,
  .Footer .navbar,
  .Footer {
    margin: 0 !important;
    width: 100% !important;
  }
}

/* Responsive media queries: */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  /* For IPad: */

  .TopNav,
  .Footer .navbar,
  .Footer,
  .MainContent {
    width: 100% !important;
    margin-left: 0 !important;
    padding-left: 0;
  }

  .MainContent {
    padding-top: 69px;
  }
}

/* Responsive media queries: */
@media only screen and (min-width: 501px) {
  /* For computer and ipad: */
  .TopNav .navbar {
    height: 70px;
  }
}

/* //Phone: */
@media only screen and (max-width: 1000px) {
  .structureRow div {
    font-size: 11px;
  }
}

/* Story and media: */
.structureRow {
  padding-top: 8px;
  padding-bottom: 8px;
}

.structureRow.slim {
  padding-top: 0;
  padding-bottom: 0;
}
.structureRow.slim.thickBottom {
  padding-bottom: 10px;
}

.structureRow.thickTop {
  padding-top: 12px;
}
.structureRow.slim.top {
  padding-top: 8px;
  padding-bottom: 0;
}

.row div {
  /* padding-left:0px !important; */
  padding-right: 0px !important;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

/*animations:*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.TopNav,
.Footer {
  z-index: 150 !important;
}

.successMsg {
  color: #00be00;
}
.errorMsg {
  color: red;
}
.warningMsg {
  color: #f69b1d;
}

.fa-download {
  color: #474747;
}

/* removes input:-internal-autofill-selected on text cache dropdown: */
input {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255, 1);
}